import './pdlist.css';
import { useState, useContext} from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Loader, Progress, Popover, Whisper, Modal, Button, Pagination, Drawer} from 'rsuite';
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { IoMdSync } from "react-icons/io";
import { MdOutlineErrorOutline } from "react-icons/md";
import { ListScContext} from '../../utils/scListContext';
import { TbFileImport } from "react-icons/tb";
import TimeLinePedido from '../timeLinePedido';
import VisualizadorComponent from '../../../../../../components/visualizador';

const PdList = ({handlePedido, handleOpenEdit, handleGroupData}) => {
    const api = useAxios();
    const { pedidoList, loadingSc, setLoadingSc, Pdpagination, setPdPagination, isFilter } = useContext(ListScContext);
    const [expandId, setExpandId] = useState(null);
    const [open, setOpen] = useState(false);
    const [pedidoNum, setPedidoNum] = useState(null);
    const [document, setDocument] = useState(null);
    const [openDocument, setOpenDocument] = useState(false);
    const [openTimeLine, setOpenTimeLine] = useState(false);
    const [timeLineData, setTimeLineData] = useState({
        data: [],
        resposta: [],
        numero: '',
        comprador: '',
        fornecedor: '',
        data_criacao: '',
        status: '',
        uuid: ''

    });







    

    const hadleActivePagePd = async (page) => {
        setLoadingSc(true)
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_list', {
                params: {
                    ...Pdpagination.querys,
                    page: page
                }
            });
            setLoadingSc(false)
            handleGroupData(response.data.results, 'isGet');
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...Pdpagination.querys,
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }


    
    
       


    






    const toggleExpand = (index) => { 
        setExpandId(prevId => (prevId === index ? null : index));
    };

   

  


    const handleStatus = (values) => {
        if(values.status === 'PENDENTE'){
            return 'APROVADA';
        }else if(values.status === "BLOQUEADO"){
            return 'EM APROVAÇÃO';
        }
        else{
            return values.status;
        }
        
    }






    const NameCell = ({ rowData, dataKey, ...props }) => {
        const speaker = (
          <Popover>
            <div style={{fontSize: 10}}>
                <p>
                <b>SOLICITANTE:</b> {rowData?.solicitacao?.solicitante}
                </p>
                <p>
                <b>CENTRO DE CUSTO:</b> {rowData?.solicitacao?.centro_custo}
                </p>
            </div>
          </Popover>
        );
        return (
          <div {...props}>
            <Whisper placement="top" speaker={speaker}>
              <a>{rowData[dataKey]}</a>
            </Whisper>
          </div>
        );
    };

    const handleDelete = async (numero) => { 
        try {
            const response = await api.patch('/api/v2/modules/gestao-compras/pedido-compra/delete_pedido/', { numero: numero })
            if(response.status === 200){
                handleGroupData(numero, 'isDelete');
                setOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleResync = async (uuid) => {
        setLoadingSc(true);
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/reenviar_pedido/', { 
                params: {
                    uuid: uuid
                }
             });
            setLoadingSc(false);
            handleGroupData(response.data, 'isGet');
        }
        catch(error){
            console.log(error);
            setLoadingSc(false);
        }
        
    }


    const handleTimeLine = (data) => {
        if(data.status !== "SY"){
            setTimeLineData({
                data: data?.data,
                resposta: data?.data[0].resposta,
                numero: data?.numero,
                comprador: data?.comprador,
                fornecedor: data?.fornecedor,
                data_criacao: data?.data_criacao,
                status: data?.status,
                uuid: data.uuid
            });
            setOpenTimeLine(true);
        }                           
    }


    const handleImport = async (data) => {
        try {
            const response = await api.post(
                `/api/v2/modules/gestao-compras/pedido-compra/import_pedido/`,data,
                // { responseType: 'blob' }
            );
            // if (typeof window !== 'undefined' && window.document) {
            //     const blob = new Blob([response.data], {
            //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            //     });
            //     const url = window.URL.createObjectURL(blob);
            //     const a = window.document.createElement('a');
            //     a.href = url;
            //     a.download = 'pedido.xlsx';
            //     window.document.body.appendChild(a);
            //     a.click();
            //     window.document.body.removeChild(a);
            //     window.URL.revokeObjectURL(url);
            // }
        

            setDocument({
                id: data.numero,
                link: response.data.url,
                titulo: `Pedido de Compra N°: ${data.numero}`,
                autor: "Sistema",
                fileType: 'xlsx',
                tipoVisualizador: 'cell'
            });
            setOpenDocument(true);
            
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
        <div className="purchase-list-container" style={{position: 'relative'}}>
            <div className="purchase-list-titles-pd">
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '15%'}}>COMPRADOR</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '-2%'}}>FORNECEDOR</div>
                <div className="purchase-list-title-item item-center">DATA DE EMISSÃO</div>
                <div className="purchase-list-title-item item-center">QTD. ITENS</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'center', marginRight: '18%'}}>STATUS</div>
                <div className="purchase-list-title-item item-center">...</div>
            </div>
            <>
            {loadingSc ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {pedidoList?.map((processos, index) => {
                    return (
                        <ul key={index}  className='purchase-list-items'>
                            <li>
                                <div className='pushase-item-container' onClick={()=>handleTimeLine(processos)}>
                                    <div className="purchase-list-item-content-pd">
                                        <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                            <span className="item-title-sm">
                                                NUMERO
                                            </span>
                                            <span className="item-manager">
                                                {`#${processos?.numero?.toUpperCase()}`}
                                            </span>
                                        </div>
                                            
                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                {processos?.comprador === null ? "INDEFINIDO" : processos?.comprador?.toUpperCase()}
                                            </span>
                                        </div>
                                        
                                        <div className="purchase-list-item custom">
                                            <span className="item-title-sm">
                                                FORNECEDOR
                                            </span>

                                            <span className="item-manager">
                                                {processos?.fornecedor}
                                            </span>
                                        </div>

                                        

                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                {moment(processos?.data_criacao).format('DD/MM/YYYY')}
                                            </span>
                                        </div>


                                        <div className="purchase-list-item">
                                            <span className="item-title-sm">
                                                QTD. ITENS
                                            </span>
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {processos?.data?.length}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item">
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {processos?.status === 'SY' ? (
                                                <>
                                                    {processos?.is_error_sync ? (
                                                        <div style={{zIndex: 100}}>
                                                        <MdOutlineErrorOutline style={{fontSize: 30, color: 'red', cursor: 'pointer'}} title='Erro na syncronização' onClick={()=>handleResync(processos.uuid)}/>
                                                        </div>
                                                    ):(
                                                        <IoMdSync style={{fontSize: 30, color: 'blue'}} title='Pendente de syncronização'/>
                                                    )}
                                                </>
                                                ):(
                                                    <>
                                                    {handleStatus(processos)}
                                                    </>
                                                )}
                                                
                                            </span>
                                        </div>

                                    

                                        <div className="sc-purchase-list-item">
                                            <span className="item-title-sm">
                                                ...
                                            </span>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                {expandId === index ? 
                                                    <MdOutlineExpandLess 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer', fontSize: 30 }}
                                                        title='Fechar' 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleExpand(index)
                                                        }} 
                                                    /> 
                                                    : 
                                                    <MdOutlineExpandMore 
                                                        title='Expandir'
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer', fontSize: 30 }} 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleExpand(index)
                                                        }} 
                                                    />
                                                    
                                                }
                                                
                                                <MdEdit
                                                className="list-documents-icon" 
                                                title='Editar'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(processos.status === 'GERADO DO GCT' || processos.status === 'ATENDIDO'){
                                                        return;
                                                    }
                                                    handleOpenEdit(processos)}}
                                                style={{ cursor: 'pointer', backgroundColor: '#dfdddd', marginLeft: 5, color: 'gray', fontSize: 30, padding: 6}}
                                                />

                                                <MdDeleteOutline 
                                                title='Excluir'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(processos.status === 'GERADO DO GCT' || processos.status === 'ATENDIDO'){
                                                        return;
                                                    }
                                                    setOpen(true); setPedidoNum(processos.numero)}
                                                }
                                                className="list-documents-icon" 
                                                style={{ cursor: 'pointer', backgroundColor: 'red', marginLeft: 5, color: '#fff', fontSize: 30, padding: 6}}
                                                />

                                                {processos.status === 'PENDENTE' && (
                                                    <TbFileImport 
                                                    title='Importar'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if(processos.status !== 'PENDENTE'){
                                                            return;
                                                        }
                                                        handleImport(processos)}
                                                    }
                                                    className="list-documents-icon" 
                                                    style={{ cursor: 'pointer', backgroundColor: '#3f3ff5', marginLeft: 5, color: '#fff', fontSize: 30, padding: 6}}
                                                    />
                                                )}
                            

                                                
                                                
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    {expandId === index && (
                                        <>
                                        {processos?.data?.map((item, index) => (
                                            <div className="pd-sub-purchase-list-item-content-child" style={{cursor: 'pointer'}}>
                                                
                                                <div className="sc-sub-purchase-list-item item-left">
                                                    <div className='item-expand'>
                                                        <span className="item-title-expadend">
                                                            PRODUTO
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.descri?.trim()}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '35%'}}>
                                                        <span className="item-title-expadend">
                                                            NÚMERO DA SC
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.numSc ? (
                                                                <>
                                                                {item?.numSc}
                                                                </>
                                                            ):(
                                                                <>
                                                                SEM SC
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            ITEM DA SC
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.itemSc || 'SEM ITEM'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand qtd'>
                                                        <span className="item-title-expadend">
                                                            QTD.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {parseInt(item?.quantidade) || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            STATUS
                                                        </span>
                                                        <span className="item-data-expand" style={{ whiteSpace: 'nowrap' }}>
                                                            {handleStatus(item)}
                                                        </span>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            
                                        ))}
                                        </>
                                        )}
                                </div>
                            </li>
                            
                        </ul>
                    );
                })}
                
                </>
                
            )}
            </>
            
            <TimeLinePedido open={openTimeLine} setOpen={setOpenTimeLine} data={timeLineData}/>
            <Modal backdrop="static" role="alertdialog" open={open} onClose={()=>setOpen(false)} size="xs">
            <Modal.Body>
                <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
                    Deseja proceder com a remoção do pedido de compra?
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={()=>handleDelete(pedidoNum)} appearance="primary">
                    Confirmar
                </Button>
                <Button onClick={()=>setOpen(false)} appearance="subtle">
                    Cancelar
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
        <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
            {!isFilter && (
            <Pagination 
                total={Pdpagination.total_items}
                limit={Pdpagination.page_size}
                size="xs"
                activePage={Pdpagination.current}
                onSelect={hadleActivePagePd}
            />
            )}
        </div>
        <Drawer placement="bottom" closeButton={false} size="full"  open={openDocument} onClose={() => setOpenDocument(false)}>
            <Drawer.Body style={{margin: 0, padding: 0}}>
                <div style={{height: '100%'}}>
                    <div style={{width: '100%', height: '40px'}}>
                        <span style={{fontSize: 20, fontWeight: 'bold', marginLeft: 10}}>Visualizador</span>
                        <Button onClick={() => setOpenDocument(false)} style={{float: 'right', marginRight: 10}} appearance="primary">Fechar</Button>
                    </div>
                    <VisualizadorComponent documento={document}/>
                </div>
            
            </Drawer.Body>
        </Drawer>
        </>
    );
}
export default PdList;