import { Routes, Route } from 'react-router-dom';
import MainLayout from '../layouts/main';
import GestaoFrotas from '../apps/gestaoFrotas/screen/MainFrotas'; 
import AberturaServico from '../apps/gestaoFrotas/screen/AberturaServico';
import GestaoOrcamento from '../apps/gestaoOrcamento/screen/MainGestaoOrc'
import MainGestaoPatrimonio from '../apps/gestaoPatrimonial/screens/main';
import MainGestaoRecursosH from '../apps/gestaoRecusosH/screens/main';
import AplicativosScreen from '../screens/AppsScreen';
import GcRouters from './gestaoCompras/routers';
import AmzRouters from './gestaoArmazen/routers';
import GqRouters from './gestaoQualidade/routers';




const Error = () => {
    return (
      <div className="folder-content">
        <div className="folder-row-2">
          <div className="table-documents apps">
            <h1>Erro 404</h1>
          </div>
        </div>
      </div>
    );
}

// GcRouters

// AmzRouters
const ModulesRouter = () => {
  return (
    <Routes>
      <Route
        path="aplicativos"
        element={
          <MainLayout>
            <AplicativosScreen />
          </MainLayout>
        }
      />
      <Route
        path="aplicativos/gestao-frotas/:id"
        element={
          <MainLayout>
            <GestaoFrotas />
          </MainLayout>
        }
      />
      <Route
        path="aplicativos/gestao-frotas/:id/abrir-servico"
        element={
          <MainLayout>
            <AberturaServico />
          </MainLayout>
        }
      />
      <Route
        path="aplicativos/gestao-orcamento/:id"
        element={
          <MainLayout>
            <GestaoOrcamento />
          </MainLayout>
        }
      />
      <Route path="aplicativos/gestao-da-qualidade/*" element={<GqRouters />} />
      <Route
        path="aplicativos/gestao-compras/*"
        element={
            <GcRouters/>
        }
      />
      <Route
        path="aplicativos/gestao-armazem/*"
        element={
          <AmzRouters/>
        }
      />
      <Route
        path="aplicativos/gestao-patrimonial/:id"
        element={
          <MainLayout>
            <MainGestaoPatrimonio />
          </MainLayout>
        }
      />
      <Route
        path="aplicativos/gestao-recursos-h/:id"
        element={
          <MainLayout>
            <MainGestaoRecursosH />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default ModulesRouter;
