import React from 'react';
import { Avatar, IconButton, Popover, Whisper, Dropdown, Badge, Input, InputGroup, Drawer, Button } from 'rsuite';
import MenuIcon from '@rsuite/icons/Menu';
import './navbar.css';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from '../../utils/store/auth';
import NoticeIcon from '@rsuite/icons/Notice';
import { BASE_URL } from '../../utils/api/base';
import SearchIcon from '@rsuite/icons/Search';
import { ENVIROMENT } from '../../utils/api/base';
import NavComponent from '../navcustom';


const Navbar = ({handleSearch}) => {
  const state = useSelector(state=> state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([])
  const ref = useRef();
  const [search, setSearch] = useState('');
  const [openNav, setOpenNav] = useState(false);



  






  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => ( 
    <Popover ref={ref} {...rest} full>
      <Dropdown.Menu onSelect={onSelect}>
        <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
          <p>{state?.token?.access === '' ? 'Acesso Público' : 'Conectado'}</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: 1, paddingRight: 20}}>
            <strong style={{marginRight: 5}}>{state?.name}</strong>
            <strong>{state?.last_name}</strong>
          </div>
        </Dropdown.Item>

        

        <Dropdown.Item divider />
        {state?.isAuthenticated ? (
          <>
          <Dropdown.Item onClick={()=>navigate('/configuracoes')}>
            Configurações
          </Dropdown.Item>
          <Dropdown.Item onClick={()=>{dispatch(logout()); navigate('/')}}>Sair</Dropdown.Item>
          </>
        ):(
          <Dropdown.Item onClick={()=>{window.location.href = '/login'}}>Conectar</Dropdown.Item>
          
        )}

          
      </Dropdown.Menu>
    </Popover>
  ));
  

  const handleSelectMenu = (eventKey, event) => {
    console.log(eventKey);
    ref.current.close();
  }


  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      onClose();
      console.log(eventKey);
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {notifications.slice(0, 5).map((notification, index) => (
            <div key={index}>
            <Dropdown.Item onClick={()=>{onClose()}} key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} icon={<NoticeIcon />} eventKey={index}>
              <div className='notification-container'>
              <div className='notification-container-row1'>
                  <p>{notification?.message}</p>
                </div>
                <div className='notification-container-row2'>
                  <p></p>
                </div>
              </div>
            </Dropdown.Item>
            
            </div>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };


  
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleSearch(search);
      setSearch('');
    }
  }

  const handleClick = () => {
    handleSearch(search);
    setSearch('');
  }


  const henderUserName = () => {
    if(state?.name === ''){
      return 'U';
    }else{
      return `${state?.name?.charAt(0, 1)?.toUpperCase()}${state?.last_name?.charAt(0, 1)?.toUpperCase()}`;
    }
  }

  return (
    <nav className='navbar-content'>
      <div className='navbar-row-01'>
        {ENVIROMENT === 'dev' && (
          <span style={{color: 'red', width: '100%', marginLeft: '1%', fontWeight: 'bold'}}>AMBIENTE DE TESTE</span>
        )}
        <div className='navbar-menu'>
          <IconButton icon={<MenuIcon />} circle size='md' onClick={()=>setOpenNav(true)} style={{marginLeft: -10, marginRight: 5}} />
        </div> 
        {state?.isAuthenticated && (
          <div style={{width: '100%',display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 10}}>
            <InputGroup className='custom-inpt-group'>
              <Input value={search} onKeyPress={handleKeyPress} placeholder='Pesquisar' onChange={(value)=>setSearch(value.toUpperCase())}/>
              <InputGroup.Button onClick={()=>handleClick()}>
                <SearchIcon style={{fontSize: 20}}/>
              </InputGroup.Button>
            </InputGroup>
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: state?.isAuthenticated ? 'space-between': 'flex-end'}}>
          {/* {state?.isAuthenticated && (
            <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
              <Badge content={notifications.length > 0 ? notifications.length : false}>
              <IconButton appearance='primary' className='navbar-alert' icon={<NoticeIcon style={{color: '#fff'}}/>}/> 
              </Badge>
            </Whisper>
          )} */}
        <Whisper
          placement="bottomEnd" trigger="click" controlId="control-id-with-dropdown" ref={ref} speaker={<MenuPopover onSelect={handleSelectMenu} />}>
          <Avatar circle src={`${BASE_URL}${state.photo}`} alt="" className='navbar-avatar'>
            {henderUserName()}
          </Avatar>
        </Whisper>
        </div>
        
        <Drawer open={openNav} placement='top' onClose={() => setOpenNav(false)}>
        <Drawer.Header style={{backgroundColor: '#00664e'}}>
          <Drawer.Title style={{color: '#fff'}}>Menu</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{margin: 0, padding: 0, backgroundColor: '#00664e', height: '100%', overflow: 'hidden'}}>
          <NavComponent/>
        </Drawer.Body>
      </Drawer>
        

        
      </div>
    </nav>
  );
};



export default Navbar;
