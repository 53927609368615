import ListSolicitacoesAmz from "./components/listSolicitacoesAmz";
import PlusIcon from '@rsuite/icons/Plus';
import { IconButton, Input, InputGroup, Popover, InputPicker, Button, Whisper } from "rsuite";
import DataFormDrawerAmz from "./components/dataFormDrawerAmz";
import { useState, useEffect, useRef } from "react";
import DataCardsAmz from "./components/dataCardsAmz";
import { useAxios } from "../../../../utils/api/api";
import { useWS } from "../../../../utils/api/ws";
import SearchIcon from '@rsuite/icons/Search';
import InputMask from 'react-input-mask';
import moment from "moment";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import FunnelIcon from '@rsuite/icons/Funnel';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

const SolicitacaoAmz = () => {
    const api = useAxios();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { sendMessage, messages, isConnected } = useWS('/ws/solicitacoes/solicitante/');
    const [open, setOpen] = useState(false);
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterOption, setFilterOption] = useState('1');
    const [loadingPicker, setLoadingPicker] = useState(false);
    const [centro_custos, setCentroCustos] = useState([]);


    useEffect(() => {
        handleGetSolicitacoes();
    }, []);


    
    const handleGroupData = (data, tipo) => {
        const createGroup = (item) => ({
            numero: item.cp_num,
            uuid: item.uuid,
            data: [item]
        });
    
        const updateGroupItem = (group, newItem) => {
            const existingItemIndex = group.data.findIndex(item => item.id === newItem.id);
            
            if (existingItemIndex !== -1) {
                const updatedData = [...group.data];
                updatedData[existingItemIndex] = {
                    ...updatedData[existingItemIndex],
                    ...newItem
                };
                return { ...group, data: updatedData };
            } else {
                // Se o item não existe, adiciona ao grupo
                return {
                    ...group,
                    data: [...group.data, newItem]
                };
            }
        };
    
        if (tipo === 'isGet') {
            // Para requisições GET, agrupa os dados normalmente
            const groupedData = data.reduce((acc, item) => {
                const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                
                if (groupIndex === -1) {
                    // Se o grupo não existe, cria um novo
                    return [...acc, createGroup(item)];
                } else {
                    // Se o grupo existe, atualiza
                    const updatedGroups = [...acc];
                    updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                    return updatedGroups;
                }
            }, []);
    
            setSolicitacoes(groupedData);
        } 
        else if (tipo === 'isUpdate') {
            setSolicitacoes(prevSolicitacoes => {
                // Primeiro, agrupa os novos dados
                const newGroups = data.reduce((acc, item) => {
                    const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                    
                    if (groupIndex === -1) {
                        return [...acc, createGroup(item)];
                    } else {
                        const updatedGroups = [...acc];
                        updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                        return updatedGroups;
                    }
                }, []);
    
                // Depois, mescla com os dados existentes
                return prevSolicitacoes.map(existingGroup => {
                    const newGroup = newGroups.find(group => group.uuid === existingGroup.uuid);
                    if (newGroup) {
                        return {
                            ...existingGroup,
                            ...newGroup,
                            data: newGroup.data.map(newItem => {
                                const existingItem = existingGroup.data.find(item => item.id === newItem.id);
                                return existingItem ? { ...existingItem, ...newItem } : newItem;
                            })
                        };
                    }
                    return existingGroup;
                });
            });
        }
    };

    


    useEffect(() => {
        if (messages.length > 0) {
            messages.forEach((message) => {
                const { type, data } = message;
                if (type === "update") {
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }else if(type === "new"){
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }
            });
        }
    }, [messages]);

    const handleGetSolicitacoes = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/');
            const dt = handleGroupData(response.data, 'isGet');
            setLoading(false);
        } catch (error) {
            if(error === 'Acesso negado'){
                setTimeout(() => {
                    navigate('/aplicativos/gestao-armazem');
                }, 1000);
                enqueueSnackbar('Acesso negado', { variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'}, autoHideDuration: 1000 });
            }
        }
    }



    const handleFilterSolicitacoes = async (value) => {
            if(value.length > 2){
                setLoading(true);
            try {
                const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/',{
                    params: {
                        filter: value,
                        type: filterOption
                        
                    }
                });
                const dt = handleGroupData(response.data, 'isGet');
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    }




    const speaker = (
        <Popover className="custom-popover">
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtro</span>
            </div>
            <div className="filter-body">
                <div className="filter-item">
                    <Input placeholder="N° da solicitação"/>
                </div>
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={centro_custos.map((item) => 
                            ({
                                label: `${item.ctt_custo} - ${item.ctt_desc01}`,
                                value: item?.ctt_custo
                            }))}
                        placeholder="Centro de custo"
                        // onSelect={(value, item) => updateFilterValue('centro_custo', item.value.trim())}
                        // onSearch={(data) => handleCentroC(data.toUpperCase())}
                        renderMenu={menu => {
                            if (loadingPicker) {
                              return (
                                <p style={{ padding: 10, color: '#999', textAlign: 'center'}}>
                                  <SpinnerIcon spin /> Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                    />
                    
                </div>
                
                <div className="filter-item">

                    <Input placeholder="Solicitante"/>
                
                </div>
                <div className="filter-body-more">
                    <div className="filter-item">
                        <InputMask 
                            mask="99/99/9999"
                            placeholder="Data inicial"
                            type="text"  
                            className="rs-input"
                        />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
                        <span style={{marginBottom: 15}}>Até</span>
                    </div>
                    <div className="filter-item">
                        <InputMask 
                            mask="99/99/9999"
                            placeholder="Data final"
                            type="text"  
                            className="rs-input"
                        />
                    </div>
                </div>
                
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );


    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };
    


    return (
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" >
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginRight: 5, alignItems: 'center'}}>
                            
                            <Whisper placement="bottomStart" speaker={speaker} ref={triggerRef} trigger="none">
                                <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<FunnelIcon style={{height: '100%'}} title="Filtrar"/>}/>
                            </Whisper>
                            <IconButton  style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<SearchIcon  style={{color: '#000', height: '100%'}}/>}/>
                            <InputGroup style={{height: 36}}>
                                    <InputGroup.Addon style={{padding: 0, margin: 0}}>
                                    <select className="input-select" defaultValue="1"  style={{fontSize: 10, height: '100%',  width: 75, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        <option value="2">N°. (SA)</option>
                                        <option value="3">Solicitante</option>
                                        <option value="4">Centro de custo</option>
                                        <option value="5">Produto</option>
                                    </select>
                                    </InputGroup.Addon>
                                <Input placeholder="Pesquisar" />
                            </InputGroup>
                        </div>
                        <div>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc mobile" icon={<PlusIcon  style={{color: '#fff', height: '100%'}}/>}></IconButton>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc" icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Nova solicitação</IconButton>
                        </div>
                        
                    </div>
                    <div className="data-cards-content">
                        <DataCardsAmz/>
                    </div>
                                        
                    <div className='container-data-main'>
                        <ListSolicitacoesAmz solicitacoes={solicitacoes} setSolicitacoes={setSolicitacoes} loading={loading} setLoading={setLoading}/>
                    </div>
                </div>    
            </div>
            <DataFormDrawerAmz open={open} setOpen={setOpen} solicitacoes={solicitacoes} setSolicitacoes={setSolicitacoes}/>
        </div>
    );
}
export default SolicitacaoAmz;

