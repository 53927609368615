import './pedido.css';
import PlusIcon from '@rsuite/icons/Plus';
import { IconButton, Input, InputGroup, Whisper, Popover, Button, Pagination, InputPicker, Modal } from "rsuite";
import { useState, useEffect, useRef, useContext } from "react";
import { useAxios } from "../../../../utils/api/api";
import { useWS } from "../../../../utils/api/ws";
import SearchIcon from '@rsuite/icons/Search';
import FunnelIcon from '@rsuite/icons/Funnel';
import InputMask from 'react-input-mask';
import moment from "moment";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useSnackbar } from 'notistack';
import PdList from "./components/listPd";
import { useNavigate } from 'react-router-dom';
import DrawerPedidoForm from './components/drawerPedidoForm';
import PdCards from './components/pdCards';
import ListScPendente from './components/listScPendente';
import { ListScContext} from './utils/scListContext';
import InputFilter from './components/inputsFilter';


const PedidoCompras = () => {
    const api = useAxios();
    const { setScList, cardSelected,  setLoadingSc,  setPedidoList, setListUser, selectedSolicitacao } = useContext(ListScContext);
    const { enqueueSnackbar } = useSnackbar();
    const { sendMessage, messages, isConnected } = useWS('/ws/solicitacoes/comprador/');
    const navigate = useNavigate();
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [centro_custos, setCentroCustos] = useState([]);
    const [loadingPicker, setLoadingPicker] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataEdit, setDataEdit] = useState(null);
    const [filterTipo, setFilterTipo] = useState(1);
    const [openCard, setOpenCard] = useState("SCP"); 
    const [pagination, setPagination] = useState({
        links:{
            next: null,
            previous: null
        },
        querys:{
            filter: '',
            status: ''
        },
        current: 1,
        total_pages: 1,
        total_items: 1,
        page_size: 1
    })

    const [Pdpagination, setPdPagination] = useState({
        links:{
            next: null,
            previous: null
        },
        querys:{
            filter: '',
            status: ''
        },
        current: 1,
        total_pages: 1,
        total_items: 1,
        page_size: 1
    })
    const [filterValue, setFilterValue] = useState({
        solicitante: '',
        data_inicio: '',
        data_fim: '',
        numero: '',
        centro_custo: ''
    });


    useEffect(() => {
        if(cardSelected === "solicitacao"){
            handleScs("", "PENDENTE");
        }else if(cardSelected === "aprovacao"){
            handleGetSolicitacoes("", "BLOQUEADO");
        }else if(cardSelected === "transporte"){
            // handleGetSolicitacoes("3");
        }else if(cardSelected === "aprovada"){
            handleGetSolicitacoes("", "PENDENTE");
        }else if(cardSelected === "cotacao"){
            handleGetSolicitacoes("5");
        }else if(cardSelected === "concluida"){
            handleGetSolicitacoes("", "CONCLUIDO");
        }else if(cardSelected === "todas"){
            handleGetSolicitacoes("", "TODAS");
        }
    }, [cardSelected]);

    

    const updateFilterValue = (field, value) => {
        setFilterValue(prevState => ({
            ...prevState,
            [field]: value
        }));
    };


    



    



    const handelUsuarios = async () => {
        try{
            const response = await api.get('/api/v2/membros/users/get_users/')
            setListUser(response.data)
    
        }catch(error){
            console.log(error)
        }
      
    }
    
    
    useEffect(() => {
    handelUsuarios()
    }, []);




    const handleGroupData = (data, tipo) => {
        const createGroup = (item) => ({
            numero: item.num,
            fornecedor: item.fornecedor_desc,
            comprador: item.comprador,
            data_criacao: item?.data_criacao,
            uuid: item?.uuid,
            is_error_sync: item?.is_error_sync,
            data: [item],
            status: item.status
        });
    
        // Função auxiliar para atualizar um item dentro de um grupo
        const updateGroupItem = (group, newItem) => {
            const existingItemIndex = group.data.findIndex(item => item.id === newItem.id);
            
            if (existingItemIndex !== -1) {
                // Se o item existe, atualiza mantendo referências anteriores
                const updatedData = [...group.data];
                updatedData[existingItemIndex] = {
                    ...updatedData[existingItemIndex],
                    ...newItem
                };
                return { ...group, data: updatedData };
            } else {
                // Se o item não existe, adiciona ao grupo
                return {
                    ...group,
                    data: [...group.data, newItem]
                };
            }
        };
    
        if (tipo === 'isGet') {
            // Para requisições GET, agrupa os dados normalmente
            const groupedData = data.reduce((acc, item) => {
                const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                
                if (groupIndex === -1) {
                    // Se o grupo não existe, cria um novo
                    return [...acc, createGroup(item)];
                } else {
                    // Se o grupo existe, atualiza
                    const updatedGroups = [...acc];
                    updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                    return updatedGroups;
                }
            }, []);
    
            setPedidoList(groupedData);
        } 
        else if (tipo === 'isUpdate') {
            setPedidoList(prevSolicitacoes => {
                // Primeiro, agrupa os novos dados
                const newGroups = data.reduce((acc, item) => {
                    const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                    
                    if (groupIndex === -1) {
                        return [...acc, createGroup(item)];
                    } else {
                        const updatedGroups = [...acc];
                        updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                        return updatedGroups;
                    }
                }, []);
    
                // Depois, mescla com os dados existentes
                return prevSolicitacoes.map(existingGroup => {
                    const newGroup = newGroups.find(group => group.uuid === existingGroup.uuid);
                    if (newGroup) {
                        return {
                            ...existingGroup,
                            ...newGroup,
                            data: newGroup.data.map(newItem => {
                                const existingItem = existingGroup.data.find(item => item.id === newItem.id);
                                return existingItem ? { ...existingItem, ...newItem } : newItem;
                            })
                        };
                    }
                    return existingGroup;
                });
            });
        }
        else if (tipo === 'isDelete') {
            setPedidoList(prevSolicitacoes => {
                return prevSolicitacoes.filter(group => group.uuid !== data);
            });
        }
    };





    useEffect(() => {
        if (messages.length > 0 && openCard !== "SCP") {
            messages.forEach((message) => {
                const { type, data } = message;
                if (type === "update") {
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }else if(type === "new"){
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }
            });
        }
    }, [messages]);



    const handleScs = async (value, status) => { 
        setLoadingSc(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/solicitacoes_list',{
                params: {
                    filter: value,
                    status: status
                }
            });
            setLoadingSc(false)
            setScList(response.data.results);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: value,
                    status: status
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        } catch (error) {
            if(error === 'Acesso negado'){
                enqueueSnackbar('Acesso negado', { variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'}, autoHideDuration: 1000 });
                setTimeout(() => {
                    navigate('/aplicativos/gestao-compras');
                }, 1000);
            }
        }
    }
    

    const handleGetSolicitacoes = async (value, status) => { 
        setLoadingSc(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_list',{
                params: {
                    filter: value,
                    status: status
                }
            });   
            setLoadingSc(false);
            handleGroupData(response.data.results, 'isGet');
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: value,
                    status: status
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        } catch (error) {
            if(error === 'Acesso negado'){
                enqueueSnackbar('Acesso negado', { variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'}, autoHideDuration: 1000 });
                setTimeout(() => {
                    navigate('/aplicativos/gestao-compras');
                }, 1000);
            }
        }
    }

    


   



    const hadleActivePagePd = async (page) => {
        setLoadingSc(true)
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_list', {
                params: {
                    ...Pdpagination.querys,
                    page: page
                }
            });
            setLoadingSc(false)
            setScList(response.data.results);
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...Pdpagination.querys,
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }




    const hadleActivePage = async (page) => {
        setLoadingSc(true)
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/solicitacoes_list', {
                params: {
                    ...pagination.querys,
                    page: page
                }
            });
            setLoadingSc(false)
            setScList(response.data.results);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...pagination.querys,
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }

    

    

    


   


    const handleOpenEdit = (data) => {
        setOpen(true);
        setDataEdit(data);
    }




    useEffect(() => {
        setFilterTipo(1);
    }, [openCard]);






    return (
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" style={{alignItems: 'center'}}>
                        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginRight: 5, alignItems: 'center'}}>
                            <InputFilter/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc mobile" icon={<PlusIcon  style={{color: '#fff', height: '100%'}}/>}></IconButton>
                            
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc" icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Novo Pedido</IconButton>
                            {/* {cardSelected === 'solicitacao' ? (
                                <IconButton onClick={()=>setOpen(true)} disabled={selectedSolicitacao.length === 0} className="btn-sc" icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Iniciar Atendimento</IconButton>
                            ):(
                                
                            )} */}
                            
                            
                            {/* <IconButton onClick={()=>setOpen(true)}  className="btn-sc" icon={<RiMoneyDollarCircleLine  className='rs-icon' style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>} style={{marginLeft: 5}}>Gerar Cotação</IconButton> */}
                        </div>
                    </div>
                    <div className="data-cards-content" style={{borderRadius: 8}}>
                        <PdCards/>
                    </div>
                                       
                    <div className='container-data-main' style={{position: 'relative'}}>
                        <>
                        {cardSelected === "solicitacao" ? (
                            <>
                            <ListScPendente/>
                            <Pagination 
                                total={pagination.total_items}
                                limit={pagination.page_size}
                                size="xs"
                                maxButtons={pagination.total_pages}
                                activePage={pagination.current} 
                                onChangePage={hadleActivePage}
                            />
                            </>
                        ):(
                            <>
                            <PdList handleOpenEdit={handleOpenEdit} setOpenCard={setOpenCard} handleGroupData={handleGroupData}/>
                            <Pagination 
                                total={Pdpagination.total_items}
                                limit={Pdpagination.page_size}
                                size="xs"
                                maxButtons={Pdpagination.total_pages}
                                activePage={Pdpagination.current} 
                                onChangePage={hadleActivePagePd}
                            />
                            </>
                        )}
                        </>
                    </div>
                </div>    
            </div>
            <DrawerPedidoForm open={open} setOpen={setOpen} handleGroupData={handleGroupData} dataEdit={dataEdit} setDataEdit={setDataEdit} setOpenCard={setOpenCard}/>
        </div>
    );
}
export default PedidoCompras;
