import { Steps, IconButton, Drawer } from 'rsuite';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@rsuite/icons/Close';
import './time-line.css';
import { useState, useEffect } from 'react';
import moment from 'moment';


import { FaCheckCircle } from "react-icons/fa";
import { TbCircleNumber1 } from "react-icons/tb";
import { TbCircleNumber2 } from "react-icons/tb";
import { TbCircleNumber3 } from "react-icons/tb";
import { TbCircleNumber4 } from "react-icons/tb";
import { TbCircleNumber5 } from "react-icons/tb";
import { TbCircleNumber6 } from "react-icons/tb";

// data: processos?.data,
//                                 resposta: processos?.data[0].resposta,
//                                 numero: processos?.numero,
//                                 comprador: processos?.comprador,
//                                 fornecedor: processos?.fornecedor,
//                                 data_criacao: processos?.data_criacao,
//                                 status: processos?.status,
//                                 uuid: processos.uuid

const TimeLinePedido = ({ open, setOpen, data }) => {
    const [expanded, setExpanded] = useState('');
    const [stepData, setStepData] = useState({
        data: [],
        resposta: [],
        numero: '',
        comprador: '',
        fornecedor: '',
        data_criacao: '',
        status: '',
        uuid: ''
    });
    const [current, setCurrent] = useState(1);
    const [isReprov, setIsRepov] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('process');

    


    const DesNova = () => {
        const tm = moment("16/12/2024", "DD/MM/YYYY");
        const emissao = moment(stepData.data_criacao);

        let dt;
        if (emissao.isAfter(tm) || emissao.isSame(tm, 'day')) {
            dt = moment(stepData.data_criacao).format('DD/MM/YYYY');
        } else {
            dt = emissao.format('DD/MM/YYYY');
        }

        return (
            <div  className='item-data active'>
                <span>
                    Comprador:{stepData.comprador === null ? 'Não encontrado' : stepData.comprador}
                </span>
                <span>N°: {stepData?.numero}</span>
                <span>Efetuado em: {dt}</span>
                <p>Fornecedor: {stepData.fornecedor?.toLowerCase()}</p>
            </div>
        )
    }




    const DesPend = () => {
        if(stepData?.status === 'BLOQUEADO'){
            const timeone = moment(stepData.data_criacao, 'YYYYMMDD')
            const timetwo = moment();
            const difference = timetwo.diff(timeone, 'days')
            return (
                <div className='item-data' style={{height: '100%'}}>
                    <p>Aprovador: {stepData.resposta?.usuario === null ? 'Não encontado' : stepData.resposta?.usuario}</p>
                    <p>Dias em aprovação: {difference}</p>
                </div>
            )
            
        }
        if(stepData?.status === "PENDENTE" || stepData?.status === "REJEITADO"){
            const timeone = moment(stepData.data_criacao, 'YYYYMMDD')
            const timetwo = moment(stepData.resposta?.cr_datalib, 'YYYYMMDD')
            const difference = timetwo.diff(timeone, 'days');
            return (
                <div className='item-data' style={{height: '100%'}}>
                    <p>Pedido {stepData?.status.toLowerCase() === "pendente" ? 'aprovado' : 'reprovado'}</p>
                    <p>O pedido ficou {difference} dias em aprovação</p>
                    {stepData?.status === 'REJEITADO' ? <p>Motivo: {stepData.resposta?.cr_obs}</p> : null}
                </div>
            )
        }else{
            const timeone = moment(stepData.data_criacao, 'YYYYMMDD')
            const timetwo = moment(stepData.resposta?.cr_datalib, 'YYYYMMDD')
            const difference = timetwo.diff(timeone, 'days');
            return (
                <div className='item-data' style={{height: '100%'}}>
                    <p>Pedido aprovado</p>
                    <p>Aprovador: {stepData.resposta?.usuario === null ? 'Não encontado' : stepData.resposta?.usuario}</p>
                    <p>O pedido ficou {difference} dias em aprovação</p>
                </div>
            )
        }
        
        
    };


    const DesCompra = () => {
        if(stepData.status === 'PENDENTE'){
            const timeone = moment(stepData.resposta?.cr_datalib, 'YYYYMMDD')
            const timetwo = moment();
            const difference = timetwo.diff(timeone, 'days')
            return (
                <div className='item-data' style={{height: 50}}>
                    <p>Pedido disponivel para compra a {difference} dias</p>
                </div>
            )
            
        }
        if(stepData.status === "COMPRADO"){
            const timeone = moment(stepData.data_criacao, 'YYYYMMDD')
            const timetwo = moment(stepData.resposta?.cr_datalib, 'YYYYMMDD')
            const difference = timetwo.diff(timeone, 'days');
            return (
                <div className='item-data' style={{height: 50}}>
                    <p>Compra Efetuada</p>
                </div>
            )
        }else{
            return (
                <div className='item-data' style={{height: 50}}>
                    <p>Pedido Comprado</p>
                </div>
            )
        }
    }

    


    const CurrentActive = (items) => {
        
        setStepData({
            resposta: items?.data[0].resposta[0],
            data: items?.data[0],
            numero: items?.numero,
            comprador: items?.comprador,
            fornecedor: items?.fornecedor,
            data_criacao: items?.data_criacao,
            status: items?.status,
            uuid: items?.uuid
        })
        if(items.status === 'BLOQUEADO'){
            setCurrent(1);
        }else if(items.status === 'PENDENTE'){
            setCurrent(2);
            // error
        }else if(items.status === 'REJEITADO'){
            setCurrent(1);
            setCurrentStatus('error')
        }else if(items.status === 'ATENDIDO'){
            setCurrentStatus('finish')
            setCurrent(4);
        }
        // if(items.status === ''){
        //     return setCurrent(2);
        // }else if(items.status === 'EA' || items.status === 'AP' || items.status === 'RG'){
        //     return setCurrent(3);
        // }else if(items.status === 'EN'){
        //     return setCurrent(4);
        // }
    }




    const DesAprovTime = ({items}) => {
        // if(items.pedido_aprov){
        //     if(items.status === 'EA'){
        //         const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
        //         const timetwo = moment();
        //         const  difference = timetwo.diff(timeone, 'days');
        //         return (
        //             <div className='item-data'>
        //                 <p>{difference} Dias Aguardando Aprovação</p>
        //             </div>
        //         )
        //     }else if(items.status === 'RG'){
        //         setCurrentStatus('error')
        //         const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
        //         const timetwo = moment(items?.pedido_aprov?.data_aproval_response, 'YYYYMMDD');
        //         const  difference = timetwo.diff(timeone, 'days');
        //         return (
        //             <div className='item-data' style={{height: 100}}>
        //                 <p>{items.pedido_aprov.usuario?.toLowerCase()}</p>
        //                 <p>Levou {difference} dias para reprovar o pedido</p>
        //                 <p>O pedido foi Reprovado em: {moment(items?.pedido_aprov?.data_aproval_response).format('DD/MM/YYYY')}</p>
        //             </div>
        //         )
        //     }else{
        //         const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
        //         const timetwo = moment(items?.pedido_aprov?.data_aproval_response, 'YYYYMMDD');
        //         const  difference = timetwo.diff(timeone, 'days');
        //         return (
        //             <div className='item-data' style={{height: 100}}>
        //                 <p>{items.pedido_aprov.usuario?.toLowerCase()}</p>
        //                 <p>Levou {difference} dias para aprovar o pedido</p>
        //                 <p>O pedido foi Aprovado em: {moment(items?.pedido_aprov?.data_aproval_response).format('DD/MM/YYYY')}</p>
        //             </div>
        //         )
        //     }
        // }

    }
    

    const PedidoConcluido = () => {
        return (
            <div className='item-data'>
                <p>Pedido Concluido</p>
            </div>
        )
    }


    useEffect(() => {
        if(open){
            setCurrentStatus('wait');
            CurrentActive(data);
        }
    }, [open]);


    return (
        <Drawer open={open} onClose={() => setOpen(false)}>
            <Drawer.Body style={{margin: 0, padding: 0}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar style={{ backgroundColor: '#00664e', paddingLeft: 10 }}>
                            <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
                            <div style={{width: '100%', padding: 10}}>
                            <h3 style={{fontSize: 12}}>TIME-LINE DO PEDIDO</h3>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div style={{width: '100%', display: 'flex', paddingTop: 10, paddingLeft: 20, paddingRight: 10}}>


                    <Steps small currentStatus={currentStatus} className='custom-step' current={current} vertical style={{marginTop: 10}}>
                    
                        <Steps.Item title="Pedido de compra"  
                        description={<DesNova/>} 
                        
                        />
                        <Steps.Item title="Pedido em aprovação"  description={
                            <DesPend/>
                        } 
                        />
                        
                        {/* 2 */}
                        <Steps.Item 
                            title="Pedido Pendente de Compra"  
                            description={<DesCompra/>} 
                        />

                        {/* 3 */}
                        <Steps.Item 
                        title="Pedido em transporte"  
                        description={<DesAprovTime/>} 
                        />

                        <Steps.Item 
                            title="Pedido Concluido"  
                            description={<PedidoConcluido/>} 
                        />


                    
                    
                        </Steps>
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
       
    );
}
export default TimeLinePedido;