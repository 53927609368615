import MainPage from './screens/mainPage';
import MainLayout from './layouts/main';
import LoginPage from './screens/login';
import ReservasScreen from './screens/reservasScreen';
import FolderMainScreen from './screens/folderMainScreen';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import UserSpaceScreen from './screens/userSpace';
import { useAuth } from './utils/context/AuthContext';
import ValidateInvite from './components/validateInvite';
import DaShboardScreen from './screens/DashboardScren';
import ModulesRouter from './modules/routers';
import UserConfig from './screens/userConfig';
import ScPublic from './screens/publicPages/ScList';

function RouterComponent() {


  const ProtectedRoutes = () => {
    const { user } = useAuth();
    if (!user.isAuthenticated) {
      alert('Você não está autenticado');
      return <Navigate to="/" replace />;
    }
    return <Outlet />;
  };



  
  return (
      <Routes>
          <Route index element={<MainLayout><MainPage/></MainLayout>}/>
          {/* <Route path="/public-sc/:uuid" element={<ScPublic/>}/> */}
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="validate-invite/:token" element={<ValidateInvite/>}/>
          <Route element={<ProtectedRoutes/>}>
            <Route path="dashboard" element={<MainLayout><DaShboardScreen/></MainLayout>}/>
            <Route path="reservas" element={<MainLayout><ReservasScreen/></MainLayout>} />
            <Route path="diretorios" element={<MainLayout><FolderMainScreen/></MainLayout>} />
            <Route path="meu-espaco" element={<MainLayout><UserSpaceScreen/></MainLayout>}/>
            <Route path="configuracoes" element={<MainLayout><UserConfig/></MainLayout>}/>
            <Route path="/*" element={<ModulesRouter/>} />
          </Route>
      </Routes> 
  );
}

export default RouterComponent;
