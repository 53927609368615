import React from 'react';
import { useState, useEffect } from 'react';
import { FiFolder } from "react-icons/fi";
import { Nav} from 'rsuite';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import { IoIosApps } from "react-icons/io";
import { RiReservedLine } from "react-icons/ri";
import './navcustom.css';

const NavComponent = ({setOpen, expand}) => {
    const state = useSelector(state=> state.auth)
    const location = useLocation();
    const navigate = useNavigate();
    const [expandNav, setExpandNav] = useState(false);
    const[pathActive, setPathActive] = useState('/')

    useEffect(()=>{
        setPathActive(location.pathname)
    }, [location])
    
    useEffect(()=>{
        setTimeout(()=>{
            setExpandNav(expand)
        }, 10)
    }, [expand])
    

    return (
        <Nav activeKey="1" className='custom-nav'>
          
        <Nav.Item className={`rs-nav ${pathActive === '/' ? 'active' : 'rs-nav'}`} onClick={() => {navigate('/')}} eventKey="1" icon={<AiTwotoneFolderOpen className='rs-sidenav-item-icon rs-icon custom' />}>
            Biblioteca Pública
        </Nav.Item>
        {state.isAuthenticated !== false && (
            <Nav.Item eventKey="2" className={`rs-nav dash ${pathActive === '/dashboard' ? 'active' : 'rs-nav dash'}`} onClick={()=>{navigate('/dashboard')}} icon={<RxDashboard className='rs-sidenav-item-icon rs-icon custom'/>}>
                Dashboard
            </Nav.Item>
        )}
        {state.isAuthenticated !== false && (
            <Nav.Item eventKey="3" className={`rs-nav ${pathActive === '/meu-espaco' ? 'active' : 'rs-nav'}`} id="my-first-step-02" onClick={() => {navigate('/meu-espaco')}} icon={<OperatePeopleIcon className='rs-sidenav-item-icon rs-icon custom'/>}>
                Meu Espaço
            </Nav.Item>
        )}
        {state.isAuthenticated !== false && (
            <Nav.Item eventKey="4" className={`rs-nav ${pathActive.includes('/aplicativos') ? 'active' : 'rs-nav'}`} onClick={() => {navigate('/aplicativos')}} icon={<IoIosApps className='rs-sidenav-item-icon rs-icon custom'/>}>
                Aplicativos
            </Nav.Item>
        )}
        {state.isAuthenticated !== false && (
            <>
            
            {expandNav && (
                <>
                <Nav.Item divider style={{backgroundColor: '#fff', height: 1, width: '100%'}}/>
                <Nav.Item panel style={{padding: '15px 20px', color: '#aaa'}}>
                GED
                </Nav.Item>
                </>
            )}
            
            <Nav.Item eventKey="2-1" className={`rs-nav ${pathActive.includes('/diretorios') ? 'active' : 'rs-nav'}`} onClick={() => {navigate('/diretorios')}} icon={<FiFolder className='rs-sidenav-item-icon rs-icon custom'/>}>Diretórios</Nav.Item>
            <Nav.Item eventKey="2-2" className={`rs-nav ${pathActive === '/reservas' ? 'active' : 'rs-nav'}`} onClick={() => {navigate('/reservas')}} icon={<RiReservedLine className='rs-sidenav-item-icon rs-icon custom'/>}>Reservas</Nav.Item>
            </>
        )}
    </Nav>
    );
};


export default NavComponent;
