import './gestao-compras.css'
import { useNavigate } from 'react-router-dom';
import pd_img from '../gestaoCompras/assets/pd.png';
import sc_img from '../gestaoCompras/assets/sc.png';
import { FaRegUser } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { useSelector } from 'react-redux';


const GestaoCompras = () => {
    const navigate = useNavigate();
    const state = useSelector(state => state.auth);
    

    return(
        <div className="folder-content">
            <div className="folder-row-2">
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        <div className="card-gestao-compras"  onClick={()=>navigate('solicitacao-compras')}>
                            <img src={sc_img} alt="sc" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                        <div className="card-gestao-compras" onClick={()=>{
                            if(state.cod_protheus !== ''){
                                navigate('pedido-compras')
                            }else{
                                alert("Usuario sem codigo vinculado ao protheus")
                            }
                        }}>
                           <img src={pd_img} alt="pd" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                        <div className="card-gestao-compras" style={{border: '1px solid gray', backgroundColor: '#00654e'}}>
                           <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <CiUser size={50} color='#fff' style={{marginBottom: 5}}/>
                           <strong style={{color: '#fff'}}>COMPRADORES</strong>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoCompras;
