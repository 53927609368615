import { Routes, Route } from 'react-router-dom';
import GestaoQualidade from '.';
import MainLayout from '../../layouts/main';
import GestaoNaoConformidades from './apps/non_conformit';




const GqRouters = () => {
  return (
    <Routes>
        <Route path="/" element={<MainLayout><GestaoQualidade/></MainLayout>} />
        <Route path="gestao-nao-conformidades" element={<MainLayout><GestaoNaoConformidades/></MainLayout>} />
    </Routes>
  );
};

export default GqRouters;
