import React, { createContext, useState, useEffect } from 'react';

export const ListScContext = createContext();

export const PedidoProvider = ({ children }) => {
    const [scList, setScList] = useState([]);
    const [pedidoList, setPedidoList] = useState([]);
    const [selectedSolicitacao, setSelectedSolicitacao] = useState([]);
    const [loadingSc, setLoadingSc] = useState(true);
    const [cardSelected, setCardSelected] = useState("solicitacao");
    const [listUser, setListUser] = useState([]);

    const handleCheckboxChange = (values) => {
        setSelectedSolicitacao(prevItems => {
            const isItemSelected = prevItems.some(item => 
                item.c1_num === values.c1_num && item.c1_item === values.c1_item
            );
            
            if (isItemSelected) {
                return prevItems.filter(item => 
                    !(item.c1_num === values.c1_num && item.c1_item === values.c1_item)
                );
            } else {
                return [...prevItems, values];
            }
        });
    };

    const handleSelectAll = () => {
        const uniqueNumbers = [...new Set(selectedSolicitacao.map(item => item.c1_num))];
        const filteredSolicitacoes = scList.filter(item => 
            uniqueNumbers.includes(item.c1_num)
        );
        setSelectedSolicitacao(filteredSolicitacoes);

        
    }

    
    const handleGroupPedido = (data, tipo) => {
        const createGroup = (item) => ({
            numero: item.numero,
            fornecedor: item.fornecedor_desc,
            comprador: `${item.comprador.first_name} ${item.comprador.last_name}`,
            data_criacao: item.data_criacao,
            uuid: item.uuid,
            is_error_sync: item.is_error_sync,
            data: [item]
        });
    
        // Função auxiliar para atualizar um item dentro de um grupo
        const updateGroupItem = (group, newItem) => {
            const existingItemIndex = group.data.findIndex(item => item.id === newItem.id);
            
            if (existingItemIndex !== -1) {
                // Se o item existe, atualiza mantendo referências anteriores
                const updatedData = [...group.data];
                updatedData[existingItemIndex] = {
                    ...updatedData[existingItemIndex],
                    ...newItem
                };
                return { ...group, data: updatedData };
            } else {
                // Se o item não existe, adiciona ao grupo
                return {
                    ...group,
                    data: [...group.data, newItem]
                };
            }
        };
    
        if (tipo === 'isGet') {
            // Para requisições GET, agrupa os dados normalmente
            const groupedData = data.reduce((acc, item) => {
                const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                
                if (groupIndex === -1) {
                    // Se o grupo não existe, cria um novo
                    return [...acc, createGroup(item)];
                } else {
                    // Se o grupo existe, atualiza
                    const updatedGroups = [...acc];
                    updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                    return updatedGroups;
                }
            }, []);
    
            setPedidoList(groupedData);
        } 
        else if (tipo === 'isUpdate') {
            setPedidoList(prevSolicitacoes => {
                // Primeiro, agrupa os novos dados
                const newGroups = data.reduce((acc, item) => {
                    const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                    
                    if (groupIndex === -1) {
                        return [...acc, createGroup(item)];
                    } else {
                        const updatedGroups = [...acc];
                        updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                        return updatedGroups;
                    }
                }, []);
    
                // Depois, mescla com os dados existentes
                return prevSolicitacoes.map(existingGroup => {
                    const newGroup = newGroups.find(group => group.uuid === existingGroup.uuid);
                    if (newGroup) {
                        return {
                            ...existingGroup,
                            ...newGroup,
                            data: newGroup.data.map(newItem => {
                                const existingItem = existingGroup.data.find(item => item.id === newItem.id);
                                return existingItem ? { ...existingItem, ...newItem } : newItem;
                            })
                        };
                    }
                    return existingGroup;
                });
            });
        }
        else if (tipo === 'isDelete') {
            setPedidoList(prevSolicitacoes => {
                return prevSolicitacoes.filter(group => group.uuid !== data);
            });
        }
    };



    return (
        <ListScContext.Provider value={{ scList, setScList, handleCheckboxChange, selectedSolicitacao, setSelectedSolicitacao, handleSelectAll, cardSelected, setCardSelected, loadingSc, setLoadingSc, pedidoList, setPedidoList, handleGroupPedido, listUser, setListUser}}>
        {children}
        </ListScContext.Provider>
    );
};
