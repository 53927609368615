import './pdlist.css';
import { useState, useContext} from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Loader, Progress, Popover, Whisper, Modal, Button} from 'rsuite';
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { IoMdSync } from "react-icons/io";
import { MdOutlineErrorOutline } from "react-icons/md";
import { ListScContext} from '../../utils/scListContext';


const PdList = ({handlePedido, handleOpenEdit, handleGroupData}) => {
    const api = useAxios();
    const { pedidoList, setPedidoList, loadingSc, setLoadingSc } = useContext(ListScContext);
    const [expandId, setExpandId] = useState(null);
    const [open, setOpen] = useState(false);
    const [pedidoUuid, setPedidoUuid] = useState(null);





    const handleProgress = (items) => {
        const data = items.data;
        const isPedido = data.filter(item => item?.status === 'EN');
        const progressPercent = (isPedido?.length / data?.length) * 100;
        return {
            percent: progressPercent,
            count: `${isPedido?.length}/${data?.length}`
        };
    };


    




    
    
       


    






    const toggleExpand = (index) => {
        
        setExpandId(prevId => (prevId === index ? null : index));
    };

   

  


    const handleStatus = (values) => {
        if(values.status === 'PENDENTE'){
            return 'APROVADA';
        }else if(values.status === "BLOQUEADO"){
            return 'EM APROVAÇÃO';
        }
        else{
            return values.status;
        }
        
    }






    const NameCell = ({ rowData, dataKey, ...props }) => {
        const speaker = (
          <Popover>
            <div style={{fontSize: 10}}>
                <p>
                <b>SOLICITANTE:</b> {rowData?.solicitacao?.solicitante}
                </p>
                <p>
                <b>CENTRO DE CUSTO:</b> {rowData?.solicitacao?.centro_custo}
                </p>
            </div>
          </Popover>
        );
        return (
          <div {...props}>
            <Whisper placement="top" speaker={speaker}>
              <a>{rowData[dataKey]}</a>
            </Whisper>
          </div>
        );
    };

    const handleDelete = async (uuid) => { 
        try {
            const response = await api.patch('/api/v2/modules/gestao-compras/pedido-compra/delete_pedido/', { uuid: uuid })
            if(response.status === 200){
                handleGroupData(uuid, 'isDelete');
                setOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleResync = async (uuid) => {
        setLoadingSc(true);
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/reenviar_pedido/', { uuid: uuid });
            handleGroupData(response.data, 'isGet');
            setLoadingSc(false);
        }
        catch(error){
            console.log(error);
            setLoadingSc(false);
        }
        
    }

    {console.log(pedidoList)}

    return (
        <div className="purchase-list-container" style={{position: 'relative'}}>
            <div className="purchase-list-titles-pd">
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '15%'}}>COMPRADOR</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '-2%'}}>FORNECEDOR</div>
                <div className="purchase-list-title-item item-center">DATA DE EMISSÃO</div>
                <div className="purchase-list-title-item item-center">QTD. ITENS</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'center', marginRight: '18%'}}>STATUS</div>
                <div className="purchase-list-title-item item-center">...</div>
            </div>
            <>
            {loadingSc ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {pedidoList?.map((processos, index) => {
                    return (
                        <ul key={index} className='purchase-list-items'>
                            <li>
                                <div className='pushase-item-container'>
                                    <div className="purchase-list-item-content-pd">
                                        <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                            <span className="item-title-sm">
                                                NUMERO
                                            </span>
                                            <span className="item-manager">
                                                {`#${processos?.numero?.toUpperCase()}`}
                                            </span>
                                        </div>
                                            
                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                {processos?.comprador === null ? "INDEFINIDO" : processos?.comprador?.toUpperCase()}
                                            </span>
                                        </div>
                                        
                                        <div className="purchase-list-item custom">
                                            <span className="item-title-sm">
                                                FORNECEDOR
                                            </span>

                                            <span className="item-manager">
                                                {processos?.fornecedor}
                                            </span>
                                        </div>

                                        

                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                {moment(processos?.data_criacao).format('DD/MM/YYYY')}
                                            </span>
                                        </div>


                                        <div className="purchase-list-item">
                                            <span className="item-title-sm">
                                                QTD. ITENS
                                            </span>
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {processos?.data?.length}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item">
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {handleStatus(processos)}
                                            </span>
                                        </div>

                                        {/* <div className="purchase-list-item">
                                            <span className="item-title-sm">
                                                PROGRESSO DA SOLICITAÇÃO
                                            </span>
                                            {processos?.numero === '00000' ? (
                                                <>
                                                {processos.is_error_sync ? (
                                                    <div style={{zIndex: 100}}>
                                                    <MdOutlineErrorOutline style={{fontSize: 30, color: 'red', cursor: 'pointer'}} title='Erro na syncronização' onClick={()=>handleResync(processos.uuid)}/>
                                                    </div>
                                                ):(
                                                    <IoMdSync style={{fontSize: 30, color: 'blue'}} title='Pendente de syncronização'/>
                                                )}
                                                </>
                                            ):(
                                                <Progress.Line showInfo={false} title='progresso' percent={handleProgress(processos).percent} className='progress-bar-data' strokeColor='#00664e'/>  
                                            )}
                                            <span className='progress-data'>{handleProgress(processos).count}</span>
                                        </div> */}

                                        <div className="sc-purchase-list-item">
                                            <span className="item-title-sm">
                                                ...
                                            </span>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                {expandId === index ? 
                                                    <MdOutlineExpandLess 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer', fontSize: 30 }}
                                                        title='Fechar' 
                                                        onClick={() => toggleExpand(index)} 
                                                    /> 
                                                    : 
                                                    <MdOutlineExpandMore 
                                                        title='Expandir'
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer', fontSize: 30 }} 
                                                        onClick={() => toggleExpand(index)} 
                                                    />
                                                    
                                                }
                                                
                                                <MdEdit
                                                className="list-documents-icon" 
                                                title='Editar'
                                                onClick={() => {
                                                    if(processos.status === 'GERADO DO GCT' || processos.status === 'BLOQUEADO'){
                                                        return;
                                                    }
                                                    handleOpenEdit(processos)}}
                                                style={{ cursor: 'pointer', backgroundColor: '#dfdddd', marginLeft: 5, color: 'gray', fontSize: 30, padding: 6}}
                                                />

                                                <MdDeleteOutline 
                                                title='Excluir'
                                                onClick={() => {
                                                    if(processos.status === 'GERADO DO GCT' || processos.status === 'BLOQUEADO'){
                                                        return;
                                                    }
                                                    setOpen(true); setPedidoUuid(processos.uuid)}
                                                }
                                                className="list-documents-icon" 
                                                style={{ cursor: 'pointer', backgroundColor: 'red', marginLeft: 5, color: '#fff', fontSize: 30, padding: 6}}
                                                />
                            

                                                
                                                
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    {expandId === index && (
                                        <>
                                        {processos?.data?.map((item, index) => (
                                            <div className="pd-sub-purchase-list-item-content-child" style={{cursor: 'pointer'}}>
                                                
                                                <div className="sc-sub-purchase-list-item item-left">
                                                    <div className='item-expand'>
                                                        <span className="item-title-expadend">
                                                            PRODUTO
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.descri?.trim()}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '35%'}}>
                                                        <span className="item-title-expadend">
                                                            NÚMERO DA SC
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.numSc ? (
                                                                <>
                                                                {item?.numSc}
                                                                </>
                                                            ):(
                                                                <>
                                                                SEM SC
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            ITEM DA SC
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.itemSc || 'SEM ITEM'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand qtd'>
                                                        <span className="item-title-expadend">
                                                            QTD.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {parseInt(item?.quant) || 0}
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            STATUS
                                                        </span>
                                                        <span className="item-data-expand" style={{ whiteSpace: 'nowrap' }}>
                                                            {handleStatus(item)}
                                                        </span>
                                                    </div>
                                                </div>                                                
                                                
                                            </div>
                                            
                                        ))}
                                        </>
                                        )}
                                </div>
                            </li>
                            
                        </ul>
                    );
                })}
                
                </>
                
            )}
            </>
            <Modal backdrop="static" role="alertdialog" open={open} onClose={()=>setOpen(false)} size="xs">
            <Modal.Body>
            <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
                Deseja proceder com a remoção do pedido de compra?
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={()=>handleDelete(pedidoUuid)} appearance="primary">
                Confirmar
            </Button>
            <Button onClick={()=>setOpen(false)} appearance="subtle">
                Cancelar
            </Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
}
export default PdList;