import './drawer-pedido-form.css';
import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Modal, Button, Radio, RadioGroup} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import PageIcon from '@rsuite/icons/Page';
import { useFormik } from 'formik';
import { ListScContext } from '../../utils/scListContext';
import ListScPendente from '../listScPendente';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});






const amzList = [
  {label: 'ALMOX PARAGOMINAS', value: '01'},
  {label: 'ALMOX PARAUAPEBAS', value: '03'},
  {label: 'MANUNTENÇÃO DIRETA', value: '93'},
  {label: 'ALMOX SOSSEGO', value: '85'},
  {label: 'ALMOX SALOBO', value: '81'},
  {label: 'ALMOX GELADO', value: '80'},
  {label: 'ALMOX S11D', value: '89'},
  {label: 'ALMOX CARAJAS', value: '92'},
  {label: 'ALMOX DIVERSOS', value: '17'},
  {label: 'ALMOX JURUTI', value: '94'},
  {label: 'ALMOX ALCOA', value: '95'},
  {label: 'ALMOX ONÇA PUMA', value: '84'},
  {label: 'ALMOX FICO III', value: '90'},
  {label: 'ALMOX FICO I', value: '87'}
]






const listCond = [
  { e4_codigo: '001', e4_descri: 'A VISTA' },
  { e4_codigo: '003', e4_descri: '60 Dias' },
  { e4_codigo: '002', e4_descri: '1X 30 Dias' },
  { e4_codigo: '004', e4_descri: 'ENTRADA,30,60' },
  { e4_codigo: '005', e4_descri: '00,30,60,90' },
  { e4_codigo: '006', e4_descri: 'TODO DIA 10' },
  { e4_codigo: '007', e4_descri: 'PGTO ANTECIPADO' },
  { e4_codigo: '008', e4_descri: '2X' },
  { e4_codigo: '009', e4_descri: '3X' },
  { e4_codigo: '010', e4_descri: '4X' },
  { e4_codigo: '011', e4_descri: '5X' },
  { e4_codigo: '012', e4_descri: '6X' },
  { e4_codigo: '013', e4_descri: 'TODO DIA 14' },
  { e4_codigo: '014', e4_descri: 'TODO DIA 28' },
  { e4_codigo: '015', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '016', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '017', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '018', e4_descri: '8X' },
  { e4_codigo: '019', e4_descri: '6X' },
  { e4_codigo: '020', e4_descri: 'TODO DIA 24' },
  { e4_codigo: '021', e4_descri: 'TODO DIA 20' },
  { e4_codigo: '022', e4_descri: '7X' },
  { e4_codigo: '023', e4_descri: '8X' },
  { e4_codigo: '024', e4_descri: '9X' },
  { e4_codigo: '025', e4_descri: '10X' },
  { e4_codigo: '026', e4_descri: '12X' },
  { e4_codigo: '027', e4_descri: 'TODO DIA 31' },
  { e4_codigo: '028', e4_descri: '5X PCC + IR 1º PARCELA' },
  { e4_codigo: '029', e4_descri: 'TODO DIA 09' },
  { e4_codigo: '030', e4_descri: '2X' },
  { e4_codigo: '031', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '032', e4_descri: 'SETE DIAS' },
  { e4_codigo: '033', e4_descri: '14 DIAS' },
  { e4_codigo: '034', e4_descri: '16 DIAS' },
  { e4_codigo: '035', e4_descri: '17 DIAS' },
  { e4_codigo: '036', e4_descri: '18 DIAS' },
  { e4_codigo: '037', e4_descri: '5% CAUCAO' },
  { e4_codigo: '038', e4_descri: '1 X 90 DIAS' },
  { e4_codigo: '039', e4_descri: '11 DIAS' },
  { e4_codigo: '040', e4_descri: '35 DIAS' },
  { e4_codigo: '041', e4_descri: '65 DIAS' },
  { e4_codigo: '042', e4_descri: '15 DIAS' },
  { e4_codigo: '043', e4_descri: '150 DIAS' },
  { e4_codigo: '044', e4_descri: '25 DIAS' },
  { e4_codigo: '045', e4_descri: '31 DIAS' },
  { e4_codigo: '046', e4_descri: '10 DIAS' },
  { e4_codigo: '047', e4_descri: '87 DIAS' },
  { e4_codigo: '048', e4_descri: '20 DIAS' },
  { e4_codigo: '049', e4_descri: '27 DIAS' },
  { e4_codigo: '050', e4_descri: '28 DIAS' },
  { e4_codigo: '051', e4_descri: 'S/ENTRADA, 30 E 60 DIAS' },
  { e4_codigo: '052', e4_descri: '59 DIAS' },
  { e4_codigo: '053', e4_descri: 'TODO DIA 29' },
  { e4_codigo: '054', e4_descri: 'TODO DIA 30' },
  { e4_codigo: '055', e4_descri: 'TODO DIA 17' },
  { e4_codigo: '056', e4_descri: 'TODO DIA 13' },
  { e4_codigo: '057', e4_descri: '24X' },
  { e4_codigo: 'C01', e4_descri: 'CARTÃO 1X' },
  { e4_codigo: 'C02', e4_descri: 'CARTÃO 2X' },
  { e4_codigo: 'C03', e4_descri: 'CARTÃO 3X' },
  { e4_codigo: 'C04', e4_descri: 'CARTÃO 4X' },
  { e4_codigo: 'C05', e4_descri: 'CARTÃO 5X' },
  { e4_codigo: 'C06', e4_descri: 'CARTÃO 6X' },
  { e4_codigo: 'C07', e4_descri: 'CARTÃO 7X' },
  { e4_codigo: 'C08', e4_descri: 'CARTÃO 8X' },
  { e4_codigo: 'C09', e4_descri: 'CARTÃO 9X' },
  { e4_codigo: 'C10', e4_descri: 'CARTÃO 10X' },
  { e4_codigo: 'C11', e4_descri: 'CARTÃO 11X' },
  { e4_codigo: 'C12', e4_descri: 'CARTÃO 12X' },
  { e4_codigo: 'C13', e4_descri: 'DIA 20' },
  { e4_codigo: '058', e4_descri: 'TODO DIA 30' }
];



const DrawerPedidoForm = ({ open, setOpen, handleGroupData, dataEdit, setDataEdit, setOpenCard }) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const api = useAxios();
  const user = useSelector((state) => state.auth);
  const { scList, setScList, handleCheckboxChange, selectedSolicitacao, setSelectedSolicitacao, listUser, setListUser, cardSelected, setCardSelected } = useContext(ListScContext);
  const [listSelected, setListSelected] = useState([]);
  const [openImport, setOpenImport] = useState(false);
  const [listFornec, setListFornec] = useState([]);
  const [productList, setProductList] = useState([]);
  const [listLoja, setListLoja] = useState([]);
  const [loadingInput, setLoadingInput] = useState(false);
  const [centroList, setCentroList] = useState([]);
  const [ItemBkp, setItemBkp] = useState([]);
  const [filialList, setFilialList] = useState([
    { value: '01', label: 'MATRIZ' },
    { value: '05', label: 'PARAUAPEBAS' },
    { value: '06', label: 'CANAA DOS CARAJAS' },
    { value: '07', label: 'MARA ROSA' },
    { value: '08', label: 'MARABA' },
  ]);
  const formik = useFormik({
    initialValues: {
      filial: '',
      percentDesconto: '',
      moedaDesconto: '',
      noFornecedor: '',
      fornecedor_desc: '',
      loja: '',
      condicaoPago: '',
      condicaoPago_desc: '',
      totalPedido: '',
      moeda: '1',
      datPrf: '',
      items: []
    }
  });


  const itemsFormik = useFormik({
    initialValues: {
      item: '',
      id: '',
      produto: '',
      quantidade: '',
      precoUnitario: '',
      um: '',
      numSc: '',
      itemSc: '', 
      datPrf: '',
      total: '',
      descri: '',
      observacao: '',
      armazem: '',
      centroCusto: '',
      centroCusto_desc: '',
      desconto: 0,
      usuarios: []

    }
  });

  const handleSearchFornec = async (value) => {
    if(value.length > 1){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value.toUpperCase(),
              table: 'Sa2010'  
          }
          
      });
      const uniqueArray = response.data.filter((item, index, self) =>
        index === self.findIndex(t => t.a2_cod.trim() === item.a2_cod.trim())
      );
      setLoadingInput(false);
      setListFornec(uniqueArray);
      } catch (error) {
        console.log(error)
      }
    }
  }



  const handleSearchLoja = async (value) => {
    if(value.length > 3){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,
              table: 'Loja'  
          }
      });
      setLoadingInput(false);
      setListLoja(response.data);
      } catch (error) {
        console.log(error)
      }
    }
  }




  const handleSearchProduct = async (value) => {
    if(value.length > 3){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sb1010'
          }
      });
        setLoadingInput(false);
        setProductList(response.data);
      } catch (error) {
        console.log(error)
      }
    }
  }


  const handleSearchCentro = async (value) => {
    setLoadingInput(true);
    try {
      const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
        params: {
            filter: value,  
            table: 'Ctt010'
        }
    });
      setLoadingInput(false);
      setCentroList(response.data);
    } catch (error) {
      console.log(error)
    }
  }






  

  

  


  function removerCaracteresEspeciais(texto) {
    return texto
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ç/g, 'c') 
        .replace(/Ç/g, 'C')
        .replace(/[^\w\s]/g, '');
  }

  const formatCurrency = (value) => {
    if (typeof value !== 'number' || isNaN(value)) return '';
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  };

  const formatPercentage = (value) => {
    if (typeof value !== 'number' || isNaN(value)) return '';
    return `${value.toFixed(2)}%`;
  };


  
  

  
  
  const parseCurrency = (formattedValue) => {
    if (!formattedValue) return 0;
  
    if (typeof formattedValue === 'number') {
      return formattedValue;
    }
  
    const onlyDigits = formattedValue.replace(/\D/g, '');
    
    // Se não houver dígitos, retorne 0
    if (!onlyDigits) return 0;
  
    // Considerando que a moeda tem duas casas decimais fixas
    const intValue = parseInt(onlyDigits, 10);
    const value = intValue / 100;
  
    return value;
  };





  useEffect(() => {
    if(selectedSolicitacao.length > 0){
        const currentItems = formik.values.items || [];
        
        const newItems = selectedSolicitacao.map((solicitacao, index) => {
            const itemExists = currentItems.some(
                item => item.numSc === solicitacao.c1_num && item.itemSc === solicitacao.c1_item
            );
            if(itemExists) {
                return null;
            }

            const nextItem = String(currentItems.length + index + 1).padStart(2, '0');
            
            return {
                ...solicitacao,
                id: solicitacao.id,
                item: nextItem,
                numSc: solicitacao.c1_num,
                itemSc: solicitacao.c1_item,
                produto: solicitacao.c1_produto?.trim(),
                quantidade: solicitacao.c1_quant,
                precoUnitario: 0,
                total: 0,
                um: solicitacao.c1_um,
                descri: solicitacao.c1_descri,
                observacao: '',
                armazem: solicitacao.c1_local,
                centroCusto: solicitacao.c1_cc,
                centroCusto_desc: solicitacao.centro_custo,
                datPrf: "",
                desconto: 0,
                usuarios: []
            }
        }).filter(Boolean);

        if(newItems.length > 0) {
            formik.setFieldValue('items', [...currentItems, ...newItems]);
        }
    }
}, [open, selectedSolicitacao]);



  const handleAddItem = () => {
    if (!itemsFormik.values.produto) {
      enqueueSnackbar("Produto é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.quantidade) {
      enqueueSnackbar("Quantidade é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.precoUnitario) {
      enqueueSnackbar("Preço unitário é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.centroCusto) {
      enqueueSnackbar("Centro de custo é obrigatório", { variant: "error" });
      return
    }
    
    if (!itemsFormik.values.desconto) {
      itemsFormik.setFieldValue('desconto', 0);

    }


    

    const nextItem = String(formik.values.items.length + 1).padStart(2, '0');
    const newItem = {
      ...itemsFormik.values,
      item: nextItem
    };
    const values = [...formik.values.items, newItem];
    formik.setFieldValue('items', values);
    formik.setFieldValue('percentDesconto', 0);
    formik.setFieldValue('moedaDesconto', 0);
    setItemBkp(values);
    
    itemsFormik.resetForm({
      values: {
        item: String(formik.values.items.length + 2).padStart(2, '0'),
        id: '',
        produto: '',
        quantidade: '',
        precoUnitario: '',
        um: '',
        numSc: '',
        itemSc: '', 
        datPrf: '',
        total: '',
        descri: '',
        observacao: '',
        armazem: '',
        centroCusto: '',
        centroCusto_desc: '',
        desconto: 0,
        usuarios: []
      }
    }); 
    setListSelected([]); 
  }


 
  
  useEffect(() => {
    if(dataEdit){
      setListFornec([{
        a2_cod: dataEdit.data[0].noFornecedor,
        a2_nome: dataEdit.data[0].fornecedor_desc,
      }]);
      setListLoja([{
        a2_loja: dataEdit.data[0].loja
      }]);
      handleSearchLoja(dataEdit.data[0].noFornecedor);
      formik.setFieldValue('datPrf', moment(dataEdit.data[0].datPrf).format('YYYY-MM-DD'));
      formik.setFieldValue('condicaoPago', dataEdit.data[0].condicaoPago);
      formik.setFieldValue('filial', dataEdit.data[0].filial);
      formik.setFieldValue('noFornecedor', dataEdit.data[0].noFornecedor);
      formik.setFieldValue('fornecedor_desc', dataEdit.data[0].fornecedor_desc);
      formik.setFieldValue('loja', dataEdit.data[0].loja);
      formik.setFieldValue('moeda', dataEdit.data[0].moeda);
      formik.setFieldValue('items', dataEdit.data);
      formik.setFieldValue('totalPedido', dataEdit.data[0]?.totalPedido);
      formik.setFieldValue('percentDesconto', dataEdit.data[0]?.percentDesconto || 0); 
      formik.setFieldValue('moedaDesconto', dataEdit.data[0]?.moedaDesconto || 0); 
      formik.setFieldValue('desconto', dataEdit.data[0]?.desconto);
      totalSum();
      setSelectedSolicitacao([]);
    }
  }, [open]);
  
  useEffect(() => {
    const totalSum = formik.values.items.reduce((sum, item) => sum + item.total, 0);
    formik.setFieldValue('totalPedido', totalSum);



  }, [formik.values.items]);



 

  const ApliPercentage = () => {
    const valorDesconto = formik.values.moedaDesconto; 
    const valorPercent = formik.values.percentDesconto;

    
    if(valorDesconto > 0 && valorPercent === 0){
      const totalGeral = formik.values.items.reduce((acc, item) => acc + item.total, 0);
      const updatedItems = formik.values.items.map((item) => {
          const proporcao = item.total / totalGeral;
          const descontoDoItem = valorDesconto * proporcao;
          const totalComDesconto = item.total - descontoDoItem;

          return {
              ...item,
              desconto: parseCurrency(descontoDoItem.toFixed(2)),
              total: parseCurrency(totalComDesconto.toFixed(2)),
          };
      });
      formik.setFieldValue('items', updatedItems);
    }else if(valorDesconto === 0 && valorPercent > 0){
        const updatedItems = formik.values.items.map((item) => {
          const totalComDesconto = item.total * (1 - valorPercent / 100);
          const desconto = item.total - totalComDesconto;

          return {
            ...item,
            desconto: parseCurrency(desconto.toFixed(2)),
            total: parseCurrency(totalComDesconto.toFixed(2)),
          };
        });
        formik.setFieldValue('items', updatedItems);
      
    }
    
  }


  
  




  const totalSum = () =>{
    let total = 0;
    total = itemsFormik.values.quantidade * itemsFormik.values.precoUnitario - itemsFormik.values.desconto
    itemsFormik.setFieldValue('total', total);
  }


  useEffect(() => {
    totalSum();
  }, [itemsFormik.values.quantidade, itemsFormik.values.precoUnitario, itemsFormik.values.desconto]);

  const openEdit = (item) => {
    setProductList([{
      b1_cod: item.produto,
      b1_desc: item.descri
    }]);
    setCentroList([{
      ctt_custo: item.centroCusto,
      ctt_desc01: item.centroCusto_desc
    }]);
    itemsFormik.setValues(item);
  }
  


  const handleConclusion = async () => {
    
    
    if (!formik.values.noFornecedor) {
      enqueueSnackbar("Fornecedor é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    if (!formik.values.condicaoPago) {
      enqueueSnackbar("Condição de pagamento é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }

    if (!formik.values.filial) {
      enqueueSnackbar("Filial é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }

    if (!formik.values.datPrf) {
      enqueueSnackbar("Data de entrega é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return
    }
    const priceIsNull = formik.values.items.some((item) => item.precoUnitario === 0 || item.precoUnitario === '');
    if (priceIsNull) {
      enqueueSnackbar("Preço unitário é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    if(formik.values.items.length === 0){
      enqueueSnackbar("Adicione um item", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    
    setLoadingRequest(true);
    const data = {
      ...formik.values,
      items: formik.values.items.map((item, index) => {
        return {
          ...item,
          item: String(index + 1).padStart(2, '0'),
          datPrf: moment(formik.values.datPrf).format('YYYYMMDD'),
          usuarios: item.usuarios.map((user) => user.id)
        }
      })
    };
    try {
      const response = await api.post(`/api/v2/modules/gestao-compras/pedido-compra/`, data);
      handleGroupData(response.data, 'isGet');
      setCardSelected("todas");
      enqueueSnackbar("Solicitação criada com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setLoadingRequest(false);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setListFornec([]);
      setProductList([]);
      setCentroList([]);
      setSelectedSolicitacao([]);
      formik.resetForm({
        values: {
          filial: '',
          noFornecedor: '',
          fornecedor_desc: '',
          totalPedido: '',
          percentDesconto: 0,
          moedaDesconto: '',
          loja: '',
          condicaoPago: '',
          condicaoPago_desc: '',
          moeda: '1',
          items: []
        }
      });
    } catch (error) {
      console.error("Error creating request:", error);
      enqueueSnackbar("Erro ao criar solicitação", { variant: "error", autoHideDuration: 2000 });
      setLoadingRequest(false);
    }
  }


  const handleConclusionEdit = async () => {
    
    setLoadingRequest(true);
    const data = {
      ...formik.values,
      items: formik.values.items.map((item) => {
        return {
          ...item,
          datPrf: moment(formik.values.datPrf).format('YYYYMMDD')
        }
      })
    };
    try {
      const response = await api.put(`/api/v2/modules/gestao-compras/pedido-compra/${dataEdit.uuid}/`, data);
      handleGroupData(response.data, 'isUpdate');
      enqueueSnackbar("Solicitação Atualizada com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setLoadingRequest(false);
      setListFornec([]);
      setProductList([]);
      setCentroList([]);
      setSelectedSolicitacao([]);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setCardSelected("aprovacao");
      formik.resetForm({
        values: {
          filial: '',
          percentDesconto: 0,
          moedaDesconto: '',
          noFornecedor: '',
          fornecedor_desc: '',
          loja: '',
          condicaoPago: '',
          totalPedido: '',
          condicaoPago_desc: '',
          moeda: '1',
          items: []
        }
      });
      setDataEdit(null);
    } catch (error) {
      console.error("Error creating request:", error);
      enqueueSnackbar("Erro ao criar solicitação", { variant: "error", autoHideDuration: 2000 });
      setLoadingRequest(false);
    }
  }


  const resetData = () => {
    setListFornec([]);
    setProductList([]);
    setCentroList([]);
    formik.resetForm({
      values: {
        filial: '',
        noFornecedor: '',
        percentDesconto: 0,
        moedaDesconto: '',
        fornecedor_desc: '',
        loja: '',
        condicaoPago: '',
        condicaoPago_desc: '',
        totalPedido: '',
        moeda: '1',
        items: []
      }
    });
    setDataEdit(null);

  }



  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2 }}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => {setOpen(false); resetData()}} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>PEDIDO DE COMPRAS</h3>
            </div>
          </Toolbar>
        </AppBar>

        <div className='form-container'>
          <div className='form-pushare pd'>
              <div className='form-pushare-top'>
                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Cond. Pgto</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou nome"
                      value={formik?.values?.condicaoPago}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listCond.map((item) => 
                        ({
                            label: `${item.e4_codigo} - ${item.e4_descri}`,
                            value: item?.e4_codigo
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value) => {
                          const item = listCond.find((item) => item.e4_codigo === value);
                          formik.setFieldValue('condicaoPago', item.e4_codigo)
                          formik.setFieldValue('condicaoPago_desc', item.e4_descri)
                        }}
                        onClean={() => {
                          formik.setFieldValue('condicaoPago', '')
                          formik.setFieldValue('condicaoPago_desc', '')
                        }}
                    />
                  </div>


                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Moeda</label>
                    <Input
                      id="product"
                      style={{width: '100%'}}
                      value='1'
                      readOnly
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '40%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Fornecedor</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou nome"
                      value={formik?.values?.noFornecedor}
                      onSearch={(value)=>handleSearchFornec(value)}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listFornec.map((item) => 
                        ({
                            label: `${item.a2_nome} - ${item.a2_cod}`,
                            value: item?.a2_cod
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value) => {
                          const item = listFornec.find((item) => item.a2_cod === value);
                          formik.setFieldValue('noFornecedor', item.a2_cod)
                          handleSearchLoja(item.a2_cod);
                          formik.setFieldValue('fornecedor_desc', item.a2_nome)
                        }}
                        onClean={() => {
                          formik.setFieldValue('noFornecedor', '');
                          formik.setFieldValue('fornecedor_desc', '')
                        }}
                    
                    />
                  </div>


                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Loja</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Loja"
                      value={formik?.values?.loja}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listLoja.map((item) => 
                        ({
                            label: `${item.a2_loja}`,
                            value: item?.a2_loja
                        }))}
                      renderMenu={(menu) => {
                          return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value) => {
                          formik.setFieldValue('loja', value)
                        }}
                        onClean={() => {
                          formik.setFieldValue('loja', '')
                      }}
                    
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Filial</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      value={formik.values.filial}
                      placeholder="Código ou nome"
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={filialList}
                      renderMenu={(menu) => {
                        return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                      onSelect={(value, item) => {
                        formik.setFieldValue('filial', item.value)
                      }}
                      onClean={() => {
                        formik.setFieldValue('filial', '')
                      }}
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Data Entrega</label>
                    <Input
                      id="product"
                      type='date'
                      style={{width: '100%'}}
                      value={formik.values.datPrf}
                      onChange={(e)=>formik.setFieldValue('datPrf', e)}
                    />
                  </div>

                  
                </div>

              </div>
              <div className='form-pushare-bottom'>
                
                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Numero (SC)</label>
                    <Input
                      id="product"
                      readOnly
                      style={{width: '100%'}}
                      value={itemsFormik.values.numSc}
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Item (SC)</label>
                    <Input
                      id="product"
                      type='number'
                      style={{width: '100%'}}
                      readOnly
                      value={itemsFormik.values.itemSc}
                    />
                  </div>

                  

                  <div className='form-group input-product-container' style={{width: '40%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Centro de Custo</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      onSearch={(value)=>handleSearchCentro(value)}
                      placeholder="Código ou nome"
                      value={itemsFormik.values.centroCusto}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                            <div style={{fontSize: 10}}>
                              {menu}
                            </div>
                        )
                      }}
                      data={centroList.map((item) => 
                        ({
                          label: `${item.ctt_custo} - ${item.ctt_desc01}`,
                          value: item?.ctt_custo
                      }))}
                      onSelect={(value) => {
                        const item = centroList.find((item) => item.ctt_custo === value);
                        itemsFormik.setFieldValue('centroCusto', item.ctt_custo)
                        itemsFormik.setFieldValue('centroCusto_desc', item.ctt_desc01)
                      }}
                      onClean={() => {
                        itemsFormik.setFieldValue('centroCusto', '')
                        itemsFormik.setFieldValue('centroCusto_desc', '')
                      }}
                    />
                  </div>

                  

                  <div className='form-group input-product-container' style={{width: '20%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Armazém</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou nome"
                      value={itemsFormik.values.armazem}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      renderMenu={(menu) => {
                        return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                      data={amzList}
                      onSelect={(value, item) => {
                        itemsFormik.setFieldValue('armazem', item.value)
                      }}
                      onClean={() => {
                        itemsFormik.setFieldValue('armazem', '')
                      }}
                    />
                  </div>

                  
                </div>



                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '50%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Produto</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou Nome"
                      onSearch={(value)=>handleSearchProduct(value)}
                      value={itemsFormik.values.produto}
                      title={itemsFormik.values.produto}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={productList.map((item) => 
                        ({
                            label: `${item.b1_cod} - ${item.b1_desc}`,
                            value: item?.b1_cod
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                              <div style={{fontSize: 9}}>
                                {menu}
                              </div>
                          )
                        }}
                        onSelect={(value) => {
                          const item = productList.find((item) => item.b1_cod === value);
                          itemsFormik.setFieldValue('produto', item.b1_cod)
                          itemsFormik.setFieldValue('um', item.b1_um)
                          itemsFormik.setFieldValue('descri', item.b1_desc)
                        }}
                        onClean={() => {
                          itemsFormik.setFieldValue('produto', '');
                          itemsFormik.setFieldValue('um', '');
                          itemsFormik.setFieldValue('descri', '');
                        }}
                    />
                  </div>
                  

                  <div className='form-group input-product-container' style={{width: '40%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Descrição</label>
                    <Input
                      id="product"
                      style={{width: '100%'}}
                      value={itemsFormik.values.descri}
                      title={itemsFormik.values.descri}
                      readOnly
                    />
                  </div>



                  <div className='form-group input-product-container' style={{width: '10%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                    <Input
                      id="product"
                      style={{width: '100%'}}
                      value={itemsFormik.values.um}
                      readOnly
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '10%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Qtd.</label>
                    <Input
                      id="product"
                      autoComplete='off'
                      onChange={(value)=>{
                        if (!isNaN(value)) {
                          itemsFormik.setFieldValue('quantidade', value);
                        }
                      }}
                      value={itemsFormik.values.quantidade}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Prc Unitario</label>
                    <Input
                      id="product"
                      type='text'
                      autoComplete='off'
                      onChange={(value) => {
                        itemsFormik.setFieldValue('precoUnitario', parseCurrency(value));
                      }}
                      value={formatCurrency(itemsFormik.values.precoUnitario)}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Desconto</label>
                    <Input
                      id="product"
                      autoComplete='off'
                      type='text'
                      onChange={(value) => {
                        itemsFormik.setFieldValue('desconto', parseCurrency(value));
                      }}
                      value={formatCurrency(itemsFormik.values.desconto)}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Vlr. Total</label>
                    <Input
                      id="product"
                      type='text'
                      readOnly
                      value={formatCurrency(itemsFormik.values.total)}
                      style={{width: '100%'}}
                    />
                  </div>
                </div>

                {/* <div style={{width: '100%', marginBottom: 20 }}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Marcar Usuarios:</label>
                  <InputPicker
                    block  
                    // value={itemsFormik.values.usuarios}
                    // onSearch={(value)=>handelUsuarios(value)}
                    onSelect={(value) => {
                      const item = listUser.find((item) => item.id === value);
                      itemsFormik.setFieldValue('usuarios', [...itemsFormik.values.usuarios, {
                        id: item.id,
                        first_name: item.first_name,
                        last_name: item.last_name
                      }])
                    }}
                    
                    placeholder="Adicionar Usuarios"
                    data={listUser.map((item) =>(
                      {
                        label: `${item.first_name} ${item.last_name}`,
                        value: item.id
                      }
                    ))}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                    />
                    <div style={{width: '100%', height: 'auto', marginTop: 10}}>
                      {itemsFormik.values?.usuarios?.map((item, index) => (
                        <Tag key={index} closable style={{fontSize: 9}} onClose={() => {
                          const novaLista = itemsFormik.values?.usuarios?.filter((_, i) => i !== index);
                          itemsFormik.setFieldValue('usuarios', novaLista);
                        }}>{item?.first_name} {item?.last_name}</Tag>
                      ))}
                    </div>
                </div> */}

                <div style={{marginTop: 30}}>
                  <Input as="textarea" rows={5} placeholder="Observação" value={itemsFormik.values.observacao} onChange={(value)=> itemsFormik.setFieldValue('observacao', removerCaracteresEspeciais(value.toUpperCase()))}/>
                </div>
              </div>
              <div style={{width: '100%', position: 'absolute', bottom: 0, height: 100, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', left: 0, zIndex: 9999, backgroundColor: '#fff'}}>
                  <IconButton  icon={<PageIcon style={{backgroundColor: '#dbd9d9'}}/>} style={{width: 150, backgroundColor: '#dbd9d9'}} size='sm' onClick={()=>setOpenImport(true)}>
                    Importar (SC)
                  </IconButton>
                  <IconButton icon={<ExpandOutlineIcon style={{backgroundColor: '#dbd9d9'}}/>} style={{width: 150, backgroundColor: '#dbd9d9'}} onClick={()=>handleAddItem()} size='sm'>
                    Adicionar
                  </IconButton>
                  <IconButton appearance='primary'  style={{backgroundColor: '#f03838', width: 150}} onClick={()=> {formik.setFieldValue('items', []); setSelectedSolicitacao([])}} icon={<CloseIcon style={{backgroundColor: '#f03838', color: '#fff'}}/>} size='sm'>
                    Cancelar
                  </IconButton>
                  {dataEdit ? (
                    <IconButton appearance='primary' style={{backgroundColor: '#00664e', width: 150}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusionEdit()} size='sm'>
                      Atualizar
                    </IconButton>
                  ):(
                    <IconButton appearance='primary'  style={{backgroundColor: '#00664e', width: 150}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                      Finalizar
                    </IconButton>
                  )}
              </div>
          </div>

          <div className='list-pushare' style={{position: 'relative', padding: 0, overflowY: 'hidden'}}>
            <div style={{padding: 10, height: '100vh', overflowY: 'auto'}}>
              <>
              {loadingRequest ? (
                <Loader content="Salvando..." center/>
              ):(
                <>
                {formik.values?.items?.length > 0 ? (
                  <>
                  <div className='list-pushare-top'>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div className='pushare-top-item'>
                        <strong>Fornecedor:</strong>
                        <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 300, cursor: 'pointer'}}>
                          {formik.values?.fornecedor_desc ? (
                            formik.values?.fornecedor_desc
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>
                      
                      <div className='pushare-top-item'>
                        <strong>Comprador: </strong>
                        <span>{user.name} {user.last_name}</span>
                      </div>
                      <div className='pushare-top-item'>
                        <strong>Filial: </strong>
                        <span>
                          {formik.values?.filial ? (
                            formik.values?.filial
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className='pushare-top-item'>
                        <strong>Data de Entrega</strong>
                        <span>
                          {formik.values?.datPrf ? (
                            moment(formik.values?.datPrf).format('DD/MM/YYYY')
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>

                      <div className='pushare-top-item'>
                        <strong>Qtd. Items</strong>
                        <span>
                          {formik.values?.items?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-container-puchare" style={{marginBottom: 160}}>
                    <div className='list-item-puchare-title pd'>
                      <span style={{textAlign: 'center', marginLeft: 0}}>Produto</span>
                      <span style={{textAlign: 'center'}}>Qtd.</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Preço Un.</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Desconto</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Preço Total</span>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...</span>
                    </div>
                  {formik.values?.items.map((item, index) => (
                    <>
                    <li key={index} className="list-item-puchare pd">
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                        <span>{item?.produto}</span>
                        <span title={item?.descri} style={{fontSize: 9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 150, cursor: 'pointer'}}>{item?.descri}</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{item?.quantidade}</span>
                      </div>
                      
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{formatCurrency(item.precoUnitario)}</span>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{formatCurrency(item.desconto)}</span>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{textAlign: 'center'}}>{formatCurrency(item?.total)}</span>
                      </div>

                      

                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <IconButton 
                        appearance='primary' 
                        style={{backgroundColor: '#bbbaba', marginRight: 5}} 
                        onClick={() => {
                          openEdit(item);
                          const updatedItems = formik.values.items.filter((value, i) => value.id !== item.id);
                          formik.setFieldValue('items', updatedItems);
                        }}
                        icon={<EditIcon style={{backgroundColor: '#bbbaba', color: '#fff'}}/>} 
                        size='sm'
                        />

                        <IconButton
                          appearance='primary'
                          style={{backgroundColor: 'red', marginRight: 5}}
                          onClick={() => {
                            if(selectedSolicitacao.length > 0){
                              handleCheckboxChange(item);
                            }
                            const updatedItems = formik.values.items.filter((value, i) => value.id !== item.id);
                            formik.setFieldValue('items', updatedItems);
                          }}
                          icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>}
                          size='sm'
                        />
                      </div>
                      
                    </li>
                    
                    </>
                    
                  ))}
                  </ul>
                  </>
              ):(
                <span>Nenhum item adicionado</span>
              )}
                </>
              )}
              </>
              
            </div>
            
            <div style={{position: 'absolute', bottom: 0, height: 100, zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row'}}>
              <div style={{width: '100%', padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '100%', height: '100%',borderRadius: 5 ,  border: '1px solid gray', marginRight: 10, padding: 5, display: 'flex', flexDirection: 'row'}}>
                  <div style={{marginRight: 10, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span style={{fontSize: 10}}>DESCONTO (R$)</span>
                    <Input 
                      type='text'
                      value={formatCurrency(formik.values.moedaDesconto)} 
                      onChange={(value) => {
                        formik.setFieldValue('moedaDesconto',parseCurrency(value));
                      }}
                      style={{fontWeight: 'bolder', height: '100%'}}
                    />
                  </div>

                  <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span style={{fontSize: 10}}>DESCONTO (%)</span>
                    <Input 
                      type='text'
                      value={formik.values.percentDesconto} 
                      onChange={(value) => {
                        if (!isNaN(value) && value <= 100){
                          formik.setFieldValue('percentDesconto', value);
                        }
                      }}
                      style={{fontWeight: 'bolder', height: '50%'}}
                    />
                  </div>
                  <div style={{display:'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: 2}}>
                    <Button appearance='primary' size='sm' disabled={formik.values.percentDesconto > 0 && formik.values.moedaDesconto > 0} style={{backgroundColor: 'gray', borderRadius:5, marginLeft: 10, fontSize: 10, width: 70}} onClick={()=>ApliPercentage()}>Aplicar</Button>
                    <Button appearance='primary' size='sm' style={{backgroundColor: 'gray', borderRadius:5, marginLeft: 10, fontSize: 10, width: 100}} onClick={()=>formik.setFieldValue('items', ItemBkp)}>Remover</Button>
                  </div>
                    
                </div>
                


                <div style={{width: '50%', height: '100%',borderRadius: 5 ,  border: '1px solid gray', padding: 5, display: 'flex', flexDirection: 'column'}}>
                  <span style={{fontSize: 10}}>TOTAL..</span>
                  <Input value={formatCurrency(formik.values.totalPedido)} type='text' style={{fontWeight: 'bolder', height: '100%'}}/>
                </div>
              </div>
            </div>
          </div>
          <Modal size="full" open={openImport} onClose={()=>{setOpenImport(false)}} style={{zIndex: 9999}}>
            <Modal.Body style={{padding: 0, margin: 0}}>
            <div style={{minHeight: 400}}>
                <ListScPendente openImport={open}/>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div>
                <Button style={{backgroundColor: 'gray', color: '#fff'}} onClick={()=>setOpenImport(false)} appearance="primary">
                Sair
                </Button>
                </div>
            </div>
            </Modal.Footer>
          </Modal>
        </div>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}/>
      </Dialog>
    </div>
  );
};

export default DrawerPedidoForm;
