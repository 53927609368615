import ListSolicitacoes from "./components/listSolicitacoes";
import './portal-solicitacao.css';
import PlusIcon from '@rsuite/icons/Plus';
import { IconButton, Input, InputGroup, Whisper, Popover, Button, Pagination, InputPicker, Dropdown } from "rsuite";
import DataFormDrawer from "./components/dataFormDrawer";
import { useState, useEffect, useRef } from "react";
import DataCards from "./components/dataCards";
import { useAxios } from "../../../../utils/api/api";
import { useWS } from "../../../../utils/api/ws";
import SearchIcon from '@rsuite/icons/Search';
import FunnelIcon from '@rsuite/icons/Funnel';
import InputMask from 'react-input-mask';
import moment from "moment";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useSnackbar } from 'notistack';






const SolicitacaoCompras = () => {
    const api = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const { messages } = useWS('/ws/solicitacoes/solicitante/');
    const [open, setOpen] = useState(false);
    const [listUser, setListUser] = useState([]);
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [centro_custos, setCentroCustos] = useState([]);
    const [loadingPicker, setLoadingPicker] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterTipo, setFilterTipo] = useState(1);    
    const [pagination, setPagination] = useState({
        url: null,
        current: 1,
        total_pages: 1,
        total_items: 1,
        page_size: 1
    })
    const [filterValue, setFilterValue] = useState({
        solicitante: '',
        data_inicio: '',
        data_fim: '',
        numero: '',
        centro_custo: ''
    });


    useEffect(() => {
        handleGetSolicitacoes();
    }, []);



    const updateFilterValue = (field, value) => {
        setFilterValue(prevState => ({
            ...prevState,
            [field]: value
        }));
    };


    const handleGroupData = (data, tipo) => {
        const createGroup = (item) => ({
            numero: item.c1_num,
            uuid: item.uuid,
            is_error_sync: item.is_error_sync,
            data: [item]
        });
    
        const updateGroupItem = (group, newItem) => {
            const existingItemIndex = group.data.findIndex(item => item.id === newItem.id);
            
            if (existingItemIndex !== -1) {
                // Se o item existe, atualiza mantendo referências anteriores
                const updatedData = [...group.data];
                updatedData[existingItemIndex] = {
                    ...updatedData[existingItemIndex],
                    ...newItem
                };
                return { ...group, data: updatedData };
            } else {
                // Se o item não existe, adiciona ao grupo
                return {
                    ...group,
                    data: [...group.data, newItem]
                };
            }
        };
    
        if (tipo === 'isGet') {
            // Para requisições GET, agrupa os dados normalmente
            const groupedData = data.reduce((acc, item) => {
                const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                
                if (groupIndex === -1) {
                    // Se o grupo não existe, cria um novo
                    return [...acc, createGroup(item)];
                } else {
                    // Se o grupo existe, atualiza
                    const updatedGroups = [...acc];
                    updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                    return updatedGroups;
                }
            }, []);
    
            setSolicitacoes(groupedData);
        } 
        else if (tipo === 'isUpdate') {
            setSolicitacoes(prevSolicitacoes => {
                // Primeiro, agrupa os novos dados
                const newGroups = data.reduce((acc, item) => {
                    const groupIndex = acc.findIndex(group => group.uuid === item.uuid);
                    
                    if (groupIndex === -1) {
                        return [...acc, createGroup(item)];
                    } else {
                        const updatedGroups = [...acc];
                        updatedGroups[groupIndex] = updateGroupItem(updatedGroups[groupIndex], item);
                        return updatedGroups;
                    }
                }, []);
    
                // Depois, mescla com os dados existentes
                return prevSolicitacoes.map(existingGroup => {
                    const newGroup = newGroups.find(group => group.uuid === existingGroup.uuid);
                    if (newGroup) {
                        return {
                            ...existingGroup,
                            ...newGroup,
                            data: newGroup.data.map(newItem => {
                                const existingItem = existingGroup.data.find(item => item.id === newItem.id);
                                return existingItem ? { ...existingItem, ...newItem } : newItem;
                            })
                        };
                    }
                    return existingGroup;
                });
            });
        }
    };





    useEffect(() => {
        if (messages.length > 0) {
            messages.forEach((message) => {
                const { type, data } = message;
                if (type === "update") {
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }else if(type === "new"){
                    if(Array.isArray(data)){
                        handleGroupData(data, 'isUpdate');
                    }else{
                        handleGroupData([data], 'isUpdate');
                    }
                }
            });
        }
    }, [messages]);

    const handleGetSolicitacoes = async () => { 
        setLoading(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/');
            const dt = handleGroupData(response.data.results, 'isGet');
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const hadleActivePage = async (page) => {
        
        setPagination(prevState => ({
            ...prevState,
            page: page
        }));

        setLoading(true);
        try {
            const response = await api.get(pagination.url, {
                params: {
                    page: page
                }
            });
            const dt = handleGroupData(response.data.results, 'isGet');
            setLoading(false);
            setPagination({
                url: pagination.url,
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleFilterSolicitacoes = async () => {
        const filter = {
            ...filterValue,
            data_inicio: filterValue.data_inicio ? moment(filterValue.data_inicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            data_fim: filterValue.data_fim ? moment(filterValue.data_fim, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
        }
        const filterString = Object.entries(filter)
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}:${value}`)
            .join(',');

        setLoading(true);
        handleOpenFilter();

        setPagination({
            url: null,
            current: 1,
            total_pages: 1,
            total_items: 0,
            page_size: 0
        });

        try {
                const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/',{
                    params: {
                        filter: filterString,   
                        page: 1
                    }
                });
                const url = response.config.url;
                const params = new URLSearchParams(response.config.params).toString().replace(/&page=\d+$/, '');
                setPagination({
                    url: `${url}?${params}`,
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
                const dt = handleGroupData(response.data.results, 'isGet');
                setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }




    const handleCentroC = async (value) => {
        if(value.length > 3){
            setLoadingPicker(true);
            try {
            const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/search_cc`, {
                params: { value }
            });
            setLoadingPicker(false);
            setCentroCustos(response.data);
            } catch (error) {
            setLoadingPicker(false);
            console.error("Error fetching Centro de Custo:", error);
            }
        }
    };


    

    const speaker = (
        <Popover className="custom-popover">
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtro</span>
            </div>
            <div className="filter-body">
                <div className="filter-item">
                    <Input placeholder="N° da solicitação" value={filterValue.numero} onChange={(value)=>updateFilterValue('numero', value)}/>
                </div>
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={centro_custos.map((item) => 
                            ({
                                label: `${item.ctt_custo} - ${item.ctt_desc01}`,
                                value: item?.ctt_custo
                            }))}
                        placeholder="Centro de custo"
                        onSelect={(value, item) => updateFilterValue('centro_custo', item.value.trim())}
                        onSearch={(data) => handleCentroC(data.toUpperCase())}
                        renderMenu={menu => {
                            if (loadingPicker) {
                              return (
                                <p style={{ padding: 10, color: '#999', textAlign: 'center'}}>
                                  <SpinnerIcon spin /> Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                    />
                    
                </div>
                
                <div className="filter-item">

                    <Input placeholder="Solicitante" value={filterValue.solicitante} onChange={(value)=>updateFilterValue('solicitante', value)}/>
                
                </div>
                <div className="filter-body-more">
                    <div className="filter-item">
                        <InputMask 
                            mask="99/99/9999"
                            placeholder="Data inicial"
                            onChange={(e)=>updateFilterValue('data_inicio', e.target.value)}
                            value={filterValue.data_inicio}
                            type="text"  
                            className="rs-input"
                        />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
                        <span style={{marginBottom: 15}}>Até</span>
                    </div>
                    <div className="filter-item">
                        <InputMask 
                            mask="99/99/9999"
                            placeholder="Data final"
                            onChange={(e)=>updateFilterValue('data_fim', e.target.value)}
                            value={filterValue.data_fim}
                            type="text"  
                            className="rs-input"
                        />
                    </div>
                </div>
                
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}} onClick={()=>handleFilterSolicitacoes()}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );


    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };

    


    const handelUsuarios = async () => {
        try{
            const response = await api.get('/api/v2/membros/users/get_users/')
            setListUser(response.data)
    
        }catch(error){
            console.log(error)
        }
      
    }
    
    
    useEffect(() => {
    handelUsuarios()
    }, []);


    const handleDynamicSearch = async (value) => {
        if(value.length > 3){
            if(filterTipo === 1 || filterTipo === 2){
                if (isNaN(value)) {
                    return enqueueSnackbar('Adicione numero na busca (SC) ou (SC)', { variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'} });
                }
            }else if(filterTipo === 3){
                if (!isNaN(value)) {
                    return enqueueSnackbar('Adicione um nome na busca', { variant: 'error', anchorOrigin: {vertical: 'top', horizontal: 'center'} });
                }
            }
            try {
                setLoading(true)
                const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/dinamic_search', {
                    params: {
                        search: value,
                        tipo: filterTipo
                    }
                });
                if(response.data.results.length === 0){
                    setLoading(false);
                    return enqueueSnackbar('Nenhum resultado encontrado', { variant: 'info', anchorOrigin: {vertical: 'top', horizontal: 'center'}, autoHideDuration: 1000 });
                }
                setLoading(false);
                const dt = handleGroupData(response.data.results, 'isGet');
                const url = response.config.url;
                const params = new URLSearchParams(response.config.params).toString().replace(/&page=\d+$/, '');
                setPagination({
                    url: `${url}?${params}`,
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        
    }



    return (
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" style={{alignItems: 'center'}}>
                        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginRight: 5, alignItems: 'center'}}>
                            
                            <Whisper placement="bottomStart" speaker={speaker} ref={triggerRef} trigger="none">
                                <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<FunnelIcon style={{height: '100%'}} title="Filtrar"/>}/>
                            </Whisper>
                            <InputGroup className="custom-input-sc">
                                    <InputGroup.Addon style={{padding: 0, margin: 0}}>
                                    <select className="input-select" defaultValue="1" onChange={(e)=>setFilterTipo(e.target.value)}>
                                        <option value="1">N°. (SC)</option>
                                        <option value="2">N°. (SA)</option>
                                        <option value="3">Solicitante</option>
                                        <option value="4">Centro de custo</option>
                                        <option value="5">Produto</option>
                                    </select>
                                    </InputGroup.Addon>
                                <Input autoComplete="off" onChange={(e)=>handleDynamicSearch(e.toUpperCase())} placeholder="Pesquisar" />
                            </InputGroup>
                        </div>
                        <div>
                        
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc mobile" icon={<PlusIcon  style={{color: '#fff', height: '100%'}}/>}></IconButton>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc" icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Nova solicitação</IconButton>
                      
                            
                        </div>
                        
                    </div>
                    <div className="data-cards-content">
                        <DataCards solicitacoes={solicitacoes}/>
                    </div>
                                        
                    <div className='container-data-main' style={{position: 'relative'}}>
                        <ListSolicitacoes solicitacoes={solicitacoes} setSolicitacoes={setSolicitacoes} loading={loading} setLoading={setLoading} handleGroupData={handleGroupData}/>
                        <div style={{padding: 3, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '#f5f4f2', position: 'absolute', bottom: 5, borderRadius: 5}}>
                            <Pagination 
                                total={pagination.total_items}
                                limit={pagination.page_size}
                                size="xs"
                                maxButtons={10}
                                activePage={pagination.current} 
                                onChangePage={hadleActivePage}
                            />
                        </div>
                    </div>
                </div>    
            </div>
            <DataFormDrawer open={open} setOpen={setOpen} solicitacoes={solicitacoes} handleGetSolicitacoes={handleGetSolicitacoes} handleGroupData={handleGroupData} listUser={listUser}/>
        </div>
    );
}
export default SolicitacaoCompras;

