import './sc-pd.css';
import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Loader, Progress, Popover, Whisper, Modal, IconButton, Dropdown, Input, Pagination} from 'rsuite';
import { useAxios } from '../../../../../../utils/api/api';
import MoreIcon from '@rsuite/icons/legacy/More';
import { ListScContext } from '../../utils/scListContext';
import { CiCircleCheck } from "react-icons/ci";
import InputFilter from '../inputsFilter';

const ListScPendente = ({openImport}) => {
    const api = useAxios();
    const { scList, setScList, handleCheckboxChange, selectedSolicitacao, handleSelectAll, loadingSc, setLoadingSc, setCardSelected, pagination, setPagination, isFilter, setIsFilter } = useContext(ListScContext);
    


    const hadleActivePage = async (page) => {
        setLoadingSc(true)
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/solicitacoes_list', {
                params: {
                    ...pagination.querys,
                    page: page
                }
            });
            setLoadingSc(false)
            setScList(response.data.results);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...pagination.querys,
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }
    

    useEffect(() => {
        setCardSelected("solicitacao")
    }, [openImport])


    


    return(
        <>
        <div className="purchase-list-container" style={{position: 'relative', padding: 0}}>
            {openImport && (
                <div style={{width: '100%', padding: 10, position: 'sticky', top: '-1px', zIndex: 9999, backgroundColor: '#fff'}}>
                    <InputFilter/>
                </div>
            )}
            <div className="purchase-list-titles-sc-pendentes" style={openImport ? {top: 50} : {top: 40}}>
                <div className="purchase-list-title-item" style={{textAlign: 'left'}}>
                    <CiCircleCheck title='Selecionar Todos' style={{fontSize: 18, color: 'gray', marginLeft: 4, cursor: 'pointer'}} className='rs-icon' onClick={()=>handleSelectAll()}/>
                    
                </div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO DA SC</div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>ITEM DA SC</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left'}}>SOLICITANTE</div>
                <div className="purchase-list-title-item" style={{textAlign: 'left', paddingLeft: 5}}>DESCRIÇÃO DO PRODUTO</div>
                <div className="purchase-list-title-item item-center">QTD.</div>
                <div className="purchase-list-title-item item-center">EMISSÃO</div>
                <div className="purchase-list-title-item item-center">NECESSIDADE</div>
                <div className="purchase-list-title-item" style={{textAlign: 'left', paddingLeft: 10}}>OBSERVAÇÃO</div>
            </div>
            {loadingSc ? (
                <Loader content="Carregando..."  center/>
            ):(
                <>
                {scList?.map((processos, index) => {
                return (
                    <ul key={processos.id} className='purchase-list-items'>
                        <li>
                            <div className='pushase-item-container'>
                                <div className="purchase-list-item-sc-pendentes">
                                    <div className="purchase-list-item custom">
                                        <span className="item-manager">
                                            <input type="checkbox" onChange={() => {handleCheckboxChange(processos)}} checked={selectedSolicitacao.some(item=> item.c1_num === processos.c1_num && item.c1_item === processos.c1_item)}/>
                                        </span>
                                    </div>

                                    <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                        <span className="item-title-sm">
                                            NUMERO
                                        </span>
                                        <span className="item-manager">
                                            {`${processos?.c1_num}`}
                                        </span>
                                    </div>
                                        
                                    <div className="purchase-list-item">
                                        <span className="item-manager">
                                            {processos?.c1_item}
                                        </span>
                                    </div>
                                    
                                    <div className="purchase-list-item custom">

                                        <span className="item-manager" title={processos?.c1_solicit} style={{width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {processos?.c1_solicit?.toUpperCase()}
                                        </span>
                                    </div>

                                    

                                    <div className="purchase-list-item" style={{display: 'flex', alignItems: 'flex-start'}}>
                                        <span className="item-manager" title={processos?.c1_descri} style={{maxWidth: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {processos?.c1_descri}
                                        </span>
                                    </div>


                                    <div className="purchase-list-item">
                                        <span className="item-title-sm">
                                            QTD. ITENS
                                        </span>
                                        <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {processos?.c1_quant}
                                        </span>
                                    </div>

                                    <div className="purchase-list-item">
                                        <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {moment(processos.c1_emissao).format('DD/MM/YYYY')}
                                        </span>
                                    </div>

                                    <div className="purchase-list-item">
                                        <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {moment(processos?.c1_datprf).format('DD/MM/YYYY')}
                                        </span>
                                    </div>

                                    <div className="purchase-list-item" style={{display: 'flex', alignItems: 'flex-start', marginLeft: 10}}>
                                        
                                        <span className="item-manager" title={processos?.c1_obs} style={{maxWidth: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {processos?.c1_obs == '' ? 'SEM OBSERVAÇÃO' : processos?.c1_obs}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                    );
            })}
                </>
            )}
            
            
            
               
        </div>
        {!isFilter && (
        <Pagination 
            total={pagination.total_items}
            limit={pagination.page_size}
            size="xs"
            maxButtons={pagination.total_pages}
            activePage={pagination.current} 
            onChangePage={hadleActivePage}
        />
        )}
        
        </>
    )
}
export default ListScPendente;