import './list-solicitacoes.css';
import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Loader, Progress, Drawer, Pagination} from 'rsuite';
import TimeLineSc from '../timeLine';
import { IoAlertCircleSharp } from "react-icons/io5";
import { IoMdSync } from "react-icons/io";
import { MdOutlineErrorOutline } from "react-icons/md";





const ListSolicitacoes = ({solicitacoes, loading, setLoading, handleGroupData}) => {
    const [expandId, setExpandId] = useState(null);
    const [open, setOpen] = useState(false);
    const[dataTimeLine, setDataTimeLine] = useState(null);
    const api = useAxios();




    const handleProgress = (items) => {
        if (items.c1_num === '0000'){
            return {
                percent: 0,
                count: '1/1'
            };
        }
        const data = items.data;
        const isPedido = data.filter(item => item.status === 'EN' || item.status === 'RG');
        const progressPercent = (isPedido?.length / data?.length) * 100;
        return {
            percent: progressPercent,
            count: `${isPedido?.length}/${data?.length}`
        };
    };
    
    


    const handleReeviarSc = async (uuid) => {  
        setLoading(true); 
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/reeviar_solicitacao/', {
                params: {
                    uuid: uuid
                }
            });
            handleGroupData(response.data, 'isGet');
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }


    
    
       

    


  



    const toggleExpand = (index) => {
        setExpandId(prevId => (prevId === index ? null : index));
    };

    

    const handleStatus = (values) => {
        if(values.status === 'PD'){
            return 'PENDENTE DE ATENDIMENTO';
        }
        if(values.status === 'AT'){
            return 'EM ATENDIMENTO';
        }else if(values.status === 'EA'){
            return 'EM APROVAÇÃO';
        }else if(values.status === 'EN'){
            return 'ENCERRADO';
        }else if(values.status === 'AP'){
            return 'APROVADO';
        }else if(values.status === 'RG'){
            return 'REJEITADO';
        }else{
            return 'INDEFINIDO';
        }
    }


    return (
        <div className="sc-purchase-list-container" style={{marginBottom: 40}}>
            <div className="sc-purchase-list-titles">
                <div className="sc-purchase-list-title-item item-left">NUMERO</div>
                <div className="sc-purchase-list-title-item">SOLICITANTE</div>
                <div className="sc-purchase-list-title-item">DATA DA SOLICITAÇÃO</div>
                <div className="sc-purchase-list-title-item">CENTRO DE CUSTO</div>
                <div className="sc-purchase-list-title-item">QTD. ITENS</div>
                <div className="sc-purchase-list-title-item">PROGRESSO</div>
                <div className="sc-purchase-list-title-item item-right">...</div>
            </div>
            <>
            {loading ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {solicitacoes.length === 0 ? (
                    <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%', fontSize: 15}}>Nenhuma solicitação encontrada</span>
                ):(
                    <>
                    {solicitacoes.map((processos, index) => {
                        return (
                            <ul key={processos.numero} className='sc-purchase-list-items'>
                                <li>
                                    <div className='sc-pushase-item-container'>
                                        <div className="sc-purchase-list-item-content">
                                            <div className="sc-purchase-list-item" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                                                <span className="item-manager number">
                                                    #{processos?.numero}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    SOLICITANTE
                                                </span>
                                                <span className="item-manager">
                                                    {processos.data[0]?.c1_solicit?.toUpperCase()}
                                                </span>
                                            </div>


                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    DATA DA SOLICITAÇÃO
                                                </span>

                                                <span className="item-manager">
                                                    {moment(processos.data[0]?.c1_emissao).format('DD/MM/YYYY')}
                                                </span>
                                            </div>



                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    CENTRO DE CUSTO
                                                </span>
                                                <span className="item-manager desktop">
                                                    {processos.data[0]?.centro_custo || 'SEM CENTRO DE CUSTO'}
                                                </span>
                                                <span className="item-manager mobile">
                                                    {processos.data[0]?.centro_custo || 'SEM CC'}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    QTD. ITENS
                                                </span>
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {processos.data.length}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    PROGRESSO
                                                </span>
                                                <div className="item-manager progress" style={{width: '100%'}}>
                                                {processos?.numero === "00000" ?(
                                                    <>
                                                    {processos.is_error_sync ? (
                                                        <>
                                                        <MdOutlineErrorOutline style={{fontSize: 20, color: 'red', cursor: 'pointer'}} title='Erro ao sincronizar' onClick={()=>handleReeviarSc(processos.uuid)}/>
                                                        </>
                                                    ):(
                                                        <IoMdSync style={{fontSize: 20, color: 'blue'}} title='Pendente de syncronização'/>
                                                    )}
                                                    
                                                    </>
                                                ): (
                                                    <>
                                                    <Progress.Line showInfo={false} title='progresso' percent={handleProgress(processos).percent} className='progress-bar-data' strokeColor='#00664e'/>
                                                    <span className='progress-data'>{handleProgress(processos).count}</span>
                                                    </>
                                                )}
                                                </div>
                                                
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    ...
                                                </span>
                                                {expandId === index ? 
                                                    <MdOutlineExpandLess 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => toggleExpand(index)} 
                                                    /> 
                                                    : 
                                                    <MdOutlineExpandMore 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => toggleExpand(index)} 
                                                    />
                                                }
                                            </div>

                                        

                                        </div>
                                        {expandId === index && (
                                        <>
                                        {processos?.data?.map((item, index) => (
                                            <div className="sc-sub-purchase-list-item-content-child" style={{cursor: 'pointer'}} onClick={()=>{setOpen(true); setDataTimeLine(item)}}>
                                                
                                                <div className="sc-sub-purchase-list-item item-left">
                                                    <div className='item-expand'>
                                                        <span className="item-title-expadend custom-item">
                                                            ITEM
                                                        </span>
                                                        <span className="item-data-expand custom-item" title={item?.c1_descri?.trim()}>
                                                            {item?.c1_descri?.trim()}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '35%'}}>
                                                        <span className="item-title-expadend">
                                                            O.S
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_os?.trim() || 'SEM O.S'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend" style={{whiteSpace: 'nowrap'}}>
                                                            CLASSE DE VALOR
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_clvl?.trim() || 'NÃO INFORMADO'}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand nf'>
                                                        <span className="item-title-expadend">
                                                            N.F.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            SEM NF
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand qtd'>
                                                        <span className="item-title-expadend">
                                                            QTD.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {parseInt(item?.c1_quant)}
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            STATUS
                                                        </span>
                                                        <span className="item-data-expand" style={{ whiteSpace: 'nowrap' }}>
                                                            {handleStatus(item)}
                                                        </span>
                                                    </div>
                                                </div>                                                
                                                
                                            </div>
                                            
                                        ))}
                                        </>
                                        )}
                                    </div>
                                </li>
                            </ul>

                        );
                    })}
                    </>
                )}
                
                </>
            )}
            </>
            
            <Drawer open={open} onClose={() => setOpen(false)} className='drawer-timeline'>
                <Drawer.Body style={{padding: 0, position: 'relative'}}>
                    <div style={{width: '100%', position: 'absolute', top:0, height: '100%', backgroundColor: '#fff', zIndex: 999}}>
                        {/* <TimeLineSc setOpen={setOpen} data={dataTimeLine}/> */}
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
           
        
    );
}
export default ListSolicitacoes;

