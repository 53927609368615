import React from 'react';
import { useState, useEffect } from 'react';
import { FiFolder } from "react-icons/fi";
import { Sidenav, Nav} from 'rsuite';
import logoIcon from '../../assets/Logotipo.png';
import logo from '../../assets/mark.png';
import './sidebar.css';
import styles from './sidebar.module.css';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import { IoIosApps } from "react-icons/io";
import { RiReservedLine } from "react-icons/ri";
import NavComponent from '../navcustom';

const Sidebar = ({setOpen}) => {
    const state = useSelector(state=> state.auth)
    const location = useLocation();
    const[expand, setExpand] = useState(true);
    const navigate = useNavigate();
    const[pathActive, setPathActive] = useState('/')

    useEffect(()=>{
        setPathActive(location.pathname)
    }, [location])
    

    

    return (
        <Sidenav expanded={expand} appearance='subtle' className={expand ? styles.sidebarContent : `${styles.sidebarContent} ${styles.sidebarContentActive}`}>
            <div className={styles.sidebarLogoContent}>
                {expand ? (
                    <img src={logo} alt='logo' className={styles.sidebarLogo} loading='lazy'/>
                    
                ) : (
                    <img src={logoIcon} alt='logo' className={styles.sidebarLogoIcon} loading='lazy'/>
                    
                )}
            </div>
            <Sidenav.Body>
                <NavComponent setOpen={setOpen} expand={expand}/>
            </Sidenav.Body>
            <div className={styles.sidebarButtom}>
                <div style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center', }}>
                    <Sidenav.Toggle expanded={expand} className='rs-nav' onToggle={() => setExpand(prev => !prev)} appearance='inverse' style={{ color: '#ffff', backgroundColor: '#f7f7f7' }}  />
                </div>
                
            </div>
        </Sidenav>
    );
};


export default Sidebar;
