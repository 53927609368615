import { useAxios } from "../../utils/api/api";
import { useEffect, useState } from "react";
import './user-profile.css';
import { Avatar, Tooltip, Whisper, Tabs, Input, InputPicker } from "rsuite";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/api/base";
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack'

const UserConfig = () => {
    const api = useAxios();
    const user = useSelector(state => state.auth);
    const [protheusUsers, setProtheusUsers] = useState([]);
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        email: '',
        whatsapp: '',
        funcao: '',
        contrato: '',
    });
    const [profileEdit, setProfileEdit] = useState({
        first_name: '',
        last_name: '',
        email: '',
        whatsapp: '',
        funcao: '',
        contrato: '',
    });

    const handleGetProfile = async () => { 
        try {
        const response = await api.get(`/api/v2/membros/users/get_profile`);
        setProfile({
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email: response.data.email,
            whatsapp: response.data.whatsapp,
            funcao: response.data.funcao,
            contrato: response.data.contrato,
            
        });
        setProfileEdit({
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email: response.data.email,
            whatsapp: response.data.whatsapp,
            funcao: response.data.funcao,
            contrato: response.data.contrato,
            
        });
        } catch (error) {
        console.error("Error: ", error);
        }
    }

    useEffect(() => {
        handleGetProfile();
    }, []);


    



    const henderUserName = () => {
        return `${profile?.first_name?.charAt(0, 1)?.toUpperCase()}${profile?.last_name?.charAt(0, 1)?.toUpperCase()}`;
    }
    
    
    const handleEditProfile = (key, value) => {
        setProfileEdit({
            ...profileEdit,
            [key]: value
        });
    }



    const handlePhoneNumber = (value) => {
        let phone = value.replace(/\D/g, ''); // Remove tudo que não for número
    
        if (!phone.startsWith('55')) {
            phone = `55${phone}`; // Adiciona "55" se não estiver presente
        }
    
        if (phone.length === 13 && phone.startsWith('559')) {
            phone = phone.replace(/^559(\d{2})(9)(\d{8})$/, '55$1$3'); // Remove o "9" extra
        }
    
        if (phone.length !== 12) {
            return null;
        }
    
        return phone;
    };
    
    const handleBlurProfile = async (key, value) => {
        if(value.length !== 0){
            if (profile[key] !== value) {
                if (key === 'whatsapp') {
                    const formattedPhone = handlePhoneNumber(value);
                    if (!formattedPhone) {
                        enqueueSnackbar('Número de telefone inválido', {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                        return;
                    }
                    value = formattedPhone;
                }
        
                try {
                    await api.post(`/api/v2/membros/users/update_profile/`, {
                        key: key,
                        value: value,
                    });
                    handleGetProfile();
                } catch (error) {
                    console.error('Error: ', error);
                }
        }
        } else {
            console.log('não atualiza');
        }
    };
    






    return (
        <div className="folder-content">
            <div className="folder-row-2">
                <div className="input-table-folder-01 user-space"> 
                    <strong>Configurações</strong>
                </div>
                <div className="table-documents user-space">
                    <div className="folder-row-2 compartilhados-container">
                    <Tabs defaultActiveKey="1" vertical className="tabs-custom" appearance="subtle" style={{width: '100%'}}>
                        <Tabs.Tab eventKey="1" title="Meu Perfil">
                        <div style={{width: '100%', paddingLeft: 25}}>
                            <div className="user-info">
                                <div className="user-info-child-01">
                                <Avatar circle src={`${BASE_URL}${user.photo}`} size="xl" alt="" className='avatar-profile'>
                                    {henderUserName()}
                                </Avatar>
                                </div>
                                <div className="user-info-child-02">
                                    <strong className="user-title">{profile?.first_name} {profile?.last_name}</strong>
                                    <span className="user-subtitle">{profile?.funcao === null ? 'Função não cadastrada': profile.funcao}</span>
                                    <span className="user-subtitle">{profile?.contrato === null ? 'Contrato não cadastrado': profile.contrato}</span>
                                </div>
                                <div className="user-info-child-03">
                                    
                                </div>  
                            </div>

                            <div className="user-info-02">
                                <div style={{display: 'flex', alignItems: 'flex-start',flexDirection: 'column', justifyContent: 'flex-start', height: '100%'}}>
                                    <strong className="user-title">Informações</strong>
                                    <div className="user-informacoes">
                                        <div className="informacoes-content">
                                            <label className="user-subtitle" style={{fontSize: 14}}>Primeiro Nome</label>
                                            <Input className="custom-inline" onBlur={(e)=>handleBlurProfile('first_name', e.target.value)} onChange={(value)=>handleEditProfile('first_name', value)} value={profileEdit?.first_name}/>
                                        </div>
                                        <div className="informacoes-content">
                                            <label className="user-subtitle" style={{fontSize: 14}}>Ultimo Nome</label>
                                            <Input className="custom-inline" onBlur={(e)=>handleBlurProfile('last_name', e.target.value)} onChange={(value)=>handleEditProfile('last_name', value)} value={profileEdit?.last_name}/>
                                        </div>
                                        <div className="informacoes-content">
                                            <label className="user-subtitle" style={{fontSize: 14}}>Email</label>
                                            <Input className="custom-inline" readOnly value={profileEdit.email}/>
                                        </div>
                                        <div className="informacoes-content">
                                            <label className="user-subtitle" style={{fontSize: 14}}>Telefone</label>
                                            <Whisper
                                                placement="bottomStart"
                                                trigger="click"
                                                speaker={<Tooltip arrow={true}>Exemplo (94) 81111111</Tooltip>}
                                            >
                                                <Input className="custom-inline" onBlur={(e)=>handleBlurProfile('whatsapp', e.target.value)} placeholder="DDD + Numero" onChange={(value)=>handleEditProfile('whatsapp', value)}  value={profileEdit.whatsapp}/>
                                            </Whisper>
                                            
                                        </div>
                                        

                                        <div className="informacoes-content">
                                            <label className="user-subtitle" style={{fontSize: 14}}>Profissão</label>
                                            <Input className="custom-inline" value={profileEdit.funcao}/>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        </Tabs.Tab>
                        <Tabs.Tab disabled eventKey="2" title="Segurança">
                        <span>Square</span>
                        </Tabs.Tab>
                        <Tabs.Tab disabled eventKey="3" title="Minha Atividade">
                        <span>Circle</span>
                        </Tabs.Tab>
                    </Tabs>                       
                    </div>
                </div>
            </div>
        </div>

    );
}
export default UserConfig;