import { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../../utils/api/base";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";
import './non_conf.css';

const GestaoNaoConformidades = () => {
    const auth = useSelector(state => state.auth);
    const iframeRef = useRef(null);
    const token = auth?.token?.access;
    const [sessionReady, setSessionReady] = useState(false);

    useEffect(() => {
        const setupDjangoSession = async () => {
            if (!token) return;

            try {
                const response = await fetch(`${BASE_URL}/api/v2/auth/create-session/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                const data = await response.json();
                
                if (data.status === 'success') {
                    console.log('Sessão criada com sucesso:', data.message);
                    if (data.csrf_token) {
                        localStorage.setItem('django_csrf_token', data.csrf_token);
                    }
                    setSessionReady(true);
                } else {
                    console.error('Erro ao criar sessão:', data.message);
                }
            } catch (error) {
                console.error('Erro ao configurar sessão:', error);
            }
        };

        setupDjangoSession();
    }, [token]);

    
    
    
    
    
    
    return (
        <div className="folder-content">
            <div className="container-puchare">
                {sessionReady ? (
                    <>
                    <iframe
                        ref={iframeRef}
                        src={`${BASE_URL}/api/v2/modules/nonconformity/`}
                        title="Non Conformity Module"
                        frameborder="0"
                        allowfullscreen
                        loading="lazy"
                        sandbox="allow-same-origin allow-scripts allow-forms allow-modals allow-popups allow-top-navigation"
                        style={{
                            width: '100%',
                            flex: 1,
                            border: 'none',
                            minHeight: '100vh',
                        }}
                    />
                    </>
                ) : (
                    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader size="md" content="Carregando..." vertical center />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GestaoNaoConformidades;