import { Input, InputGroup} from "rsuite";
import './main-page.css';
import SearchIcon from '@rsuite/icons/Search';
import TablePublic from "../../components/tablePublic";
import imgLogo from '../../assets/main.png';

const MainPage = () => {






    return (
        <div className="folder-content">
            <div className="folder-row-2">
                <div className="input-table-folder-01"  style={{justifyContent: 'flex-end'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}> 
                        <InputGroup style={{width: '100%'}}>
                            <Input placeholder="Pesquisar..."/>
                            <InputGroup.Addon style={{backgroundColor: '#53525200', fontSize: 8}}>
                                <SearchIcon/>
                            </InputGroup.Addon>
                        </InputGroup>
                    </div>
                </div>
                <div className='table-documents main'>
                    <div className="main-img" >
                        <img src={imgLogo} alt='main' loading='lazy' className="img-main"/>
                    </div>
                    <TablePublic/>  
                </div>
            </div>
        </div>
       
    );
}
export default MainPage;