import './list-solicitacoes.css';
import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Loader, Progress, Drawer, Button, Modal } from 'rsuite';
import TimeLineSc from '../timeLine';
import { IoAlertCircleSharp } from "react-icons/io5";
import { IoMdSync } from "react-icons/io";

const ListSolicitacoesAmz = ({ solicitacoes, setSolicitacoes, loading, setLoading }) => {
    const api = useAxios();
    const [activePage, setActivePage] = useState(1);
    const [expandedItem, setExpandedItem] = useState(null);
    const [expandId, setExpandId] = useState(null);
    const [open, setOpen] = useState(false);
    const [dataTimeLine, setDataTimeLine] = useState(null);
    const [statusColor, setStatusColor] = useState({
        bloqueada: '#A9A9A9',
        reprovada: '#B22222',
        rejeitada: '#D2691E',
        pendente: '#DAA520',
        cotacao: '#4682B4',
        atendida: '#2E8B57',
        parcial: '#6A5ACD',
    });

    const handleProgress = (items) => {
        if (items.cp_num === '0000') {
            return {
                percent: 0,
                count: '1/1'
            };
        }
        const data = items.data;
        const isPedido = data.filter(item => item?.status === 'AP' || item?.status === 'RG');
        const progressPercent = (isPedido?.length / data?.length) * 100;
        return {
            percent: progressPercent,
            count: `${isPedido?.length}/${data?.length}`
        };
    };

    const handleStatusSol = (item) => {
        // Implementar lógica de status
    };

    const henderDescricao = (processos, index) => (
        <>
            <div className="sc-purchase-list-item descricao active">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span className="item-title-sm">
                        DESCRIÇÃO
                    </span>
                    <span className="item-manager descri-more">
                        {expandedItem === index ? 'Menos...' : 'Mais...'}
                    </span>
                </div>
                <hr style={{ height: 2, width: '100%' }} />
                <span style={{ wordWrap: 'break-word', width: '100%', overflow: 'hidden', textAlign: 'left' }}>
                    {processos?.data[0].cp_descri}
                </span>
            </div>
        </>
    );

    const toggleExpand = (index) => {
        setExpandId(prevId => (prevId === index ? null : index));
    };

    const HandleTimeLine = () => {
        <></>
    };

    return (
        <div className="sc-purchase-list-container">
            <div className="sc-purchase-list-titles">
                <div className="sc-purchase-list-title-item item-left">NUMERO</div>
                <div className="sc-purchase-list-title-item">SOLICITANTE</div>
                <div className="sc-purchase-list-title-item">DATA DA SOLICITAÇÃO</div>
                <div className="sc-purchase-list-title-item">CENTRO DE CUSTO</div>
                <div className="sc-purchase-list-title-item">QTD. ITENS</div>
                <div className="sc-purchase-list-title-item">PROGRESSO</div>
                <div className="sc-purchase-list-title-item item-right">...</div>
            </div>
            <>
                {loading ? (
                    <div style={{ marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader center content="Carregando..." style={{ position: 'relative', marginTop: '5%' }} />
                    </div>
                ) : (
                    <>
                        {solicitacoes.length === 0 ? (
                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%', fontSize: 15 }}>Nenhuma solicitação encontrada</span>
                        ) : (
                            <>
                                {solicitacoes.map((processos, index) => {
                                    return (
                                        <ul key={index} className='sc-purchase-list-items'>
                                            <li>
                                                <div className='sc-pushase-item-container'>
                                                    <div className="sc-purchase-list-item-content">
                                                        <div className="sc-purchase-list-item" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                            <span className="item-manager">
                                                                #{processos.numero}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                SOLICITANTE
                                                            </span>
                                                            <span className="item-manager">
                                                                {processos.data[0]?.cp_solicit?.toUpperCase()}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                DATA DA SOLICITAÇÃO
                                                            </span>
                                                            <span className="item-manager">
                                                                {moment(processos.data[0].cp_emissao).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                CENTRO DE CUSTO
                                                            </span>
                                                            <span className="item-manager desktop">
                                                                {processos.data[0]?.centro_custo || 'SEM CENTRO DE CUSTO'}
                                                            </span>
                                                            <span className="item-manager mobile">
                                                                {processos.data[0]?.centro_custo || 'SEM CC'}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                QTD. ITENS
                                                            </span>
                                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                {processos.data.length}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                PROGRESSO
                                                            </span>
                                                            {processos?.data[0]?.sync === false ? (
                                                                <>
                                                                    <IoMdSync style={{ fontSize: 20, color: 'red' }} title='Syncronizar Novamente' />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Progress.Line showInfo={false} title='progresso' percent={handleProgress(processos).percent} className='progress-bar-data' strokeColor='#00664e' />
                                                                    <span className='progress-data'>{handleProgress(processos).count}</span>
                                                                </>
                                                            )}
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                ...
                                                            </span>
                                                            {expandId === index ?
                                                                <MdOutlineExpandLess
                                                                    className="list-documents-icon"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => toggleExpand(index)}
                                                                />
                                                                :
                                                                <MdOutlineExpandMore
                                                                    className="list-documents-icon"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => toggleExpand(index)}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                    {expandId === index && (
                                                        <>
                                                            {processos?.data?.map((item, index) => (
                                                                <div className="sc-sub-purchase-list-item-content" style={{ cursor: 'pointer' }}>
                                                                    <div className="sc-sub-purchase-list-item item-left">
                                                                        <div className='item-expand'>
                                                                            <span className="item-title-expadend">
                                                                                ITEM
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_descri?.trim()}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand' style={{ display: 'flex', alignItems: 'center', marginRight: '35%' }}>
                                                                            <span className="item-title-expadend">
                                                                                O.S
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_numos?.trim() || 'SEM O.S'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand' style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
                                                                            <span className="item-title-expadend">
                                                                                N.F.
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                SEM NF
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand' style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="item-title-expadend">
                                                                                QTD.
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {parseInt(item?.cp_quant)}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand' style={{ display: 'flex', alignItems: 'center', marginRight: '45%' }}>
                                                                            <span className="item-title-expadend">
                                                                                STATUS
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.status === 'PD' ? 'PENDENTE' : item?.status === 'AT' ? 'EM ATENDIMENTO' : item?.status === 'EA' ? 'EM APROVAÇÃO' : item?.status === 'AP' ? 'APROVADO' : item?.status === 'RG' ? 'REJEITADO' : item?.status === 'AD' ? 'ATENDIDO' : item?.status === 'AG' ? 'AGUARDANDO ENTREGA' : item?.status === 'DS' ? 'DISPONIVEL PARA RETIRADA' : item?.status === 'FI' ? 'FINALIZADO' : item?.status === 'CA' ? 'CANCELADO' : 'INDEFINIDO'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </>

            <Drawer open={open} onClose={() => setOpen(false)} style={{ width: '22%' }}>
                <Drawer.Body style={{ padding: 0, position: 'relative' }}>
                    <div style={{ width: '100%', position: 'absolute', top: 0, height: '100%', backgroundColor: '#fff', zIndex: 999 }}>
                        <TimeLineSc setOpen={setOpen} data={dataTimeLine} />
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

export default ListSolicitacoesAmz;