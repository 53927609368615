import './data-form.css';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Toggle, TagPicker, Button} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const amzList = [
  {NNR_DESCRI: 'ALMOX PARAGOMINAS', NNR_CODIGO: '01'},
  {NNR_DESCRI: 'ALMOX PARAUAPEBAS', NNR_CODIGO: '03'},
  {NNR_DESCRI: 'MANUNTENÇÃO DIRETA', NNR_CODIGO: '93'},
  {NNR_DESCRI: 'ALMOX SOSSEGO', NNR_CODIGO: '85'},
  {NNR_DESCRI: 'ALMOX SALOBO', NNR_CODIGO: '81'},
  {NNR_DESCRI: 'ALMOX GELADO', NNR_CODIGO: '80'},
  {NNR_DESCRI: 'ALMOX S11D', NNR_CODIGO: '89'},
  {NNR_DESCRI: 'ALMOX CARAJAS', NNR_CODIGO: '92'},
  {NNR_DESCRI: 'ALMOX DIVERSOS', NNR_CODIGO: '17'},
  {NNR_DESCRI: 'ALMOX JURUTI', NNR_CODIGO: '94'},
  {NNR_DESCRI: 'ALMOX ALCOA', NNR_CODIGO: '95'},
  {NNR_DESCRI: 'ALMOX ONÇA PUMA', NNR_CODIGO: '84'},
  {NNR_DESCRI: 'ALMOX FICO III', NNR_CODIGO: '90'},
  {NNR_DESCRI: 'ALMOX FICO I', NNR_CODIGO: '87'}
]




  

const filialList = [
  { value: '01', label: 'Matriz' },
  { value: '05', label: 'Parauapebas' },
  { value: '06', label: 'Canaa dos Carajas' },
  { value: '07', label: 'Mara Rosa' },
  { value: '08', label: 'Maraba' },
]

const DataFormDrawer = ({ open, setOpen, solicitacoes, handleGetSolicitacoes, handleGroupData, listUser }) => {
  const user = useSelector((state) => state.auth);
  const api = useAxios();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [productList, setProductList] = useState([]);
  const [centroCList, setCentroCList] = useState([]);
  const [listAmz, setListAmz] = useState([]);
  const [listPrd, setListPrd] = useState([]);
  const [listCls, setListCls] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loadingInput, setLoadingInput] = useState(false);
  const [valueUsuario, setValueUsuario] = useState('');
  const [isPropagate, setIsPropagate] = useState(false);
  const [formData, setFormData] = useState({
    dataemissao: moment().format('YYYYMMDD'),
    usuario: user.userId,
    solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
    filial: "",
    items: [],
    comprador_preferencial: null
  });
  
  const [formValue, setFormValue] = useState({
    C1_ITEM: '',
    C1_PRODUTO: '',
    C1_UM: '',
    C1_SOLICIT: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
    C1_DESCRI: '',
    C1_QUANT: 1,
    C1_CLVL: '',
    C1_OP: '',
    C1_ITEMCTA: '',
    C1_OBS: '',
    C1_LOCAL: '',
    C1_CC: '',
    C1_DATPRF: '',
    CUSTOM_CLASSIFICATION: '',
    acompanhantes: []
  });

  const handleProduto = async (value) => {
    setLoadingInput(true);
    if(value.length > 2){
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sb1010'
          }
        });
        setLoadingInput(false);
        setProductList(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoadingInput(false);
      }
    }else{
      setLoadingInput(false);
    }
  };

  const selectProduct = (item) => {
    let prd = productList.filter((prd) => prd.b1_cod === item)[0];
    setFormValue({ ...formValue, C1_PRODUTO: item, C1_DESCRI: prd.b1_desc, C1_UM: prd.b1_um });
  };

  const handleCentroC = async (value) => {
    setLoadingInput(true);
    if(value.length > 3){
      
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/search_cc`, {
          params: { value }
        });
        setLoadingInput(false);
        setCentroCList(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching Centro de Custo:", error);
      }
    }
  };



  const selectCentroC = (item) => {
    // setCentroCList([]);
    setFormValue({ ...formValue, C1_CC: item });
  };




  const selectFilial = (item) => {
    setFormValue({
      C1_ITEM: '',
      C1_PRODUTO: '',
      C1_UM: '',
      C1_SOLICIT: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
      C1_DESCRI: '',
      C1_QUANT: 1,
      C1_CLVL: '',
      C1_OP: '',
      C1_ITEMCTA: '',
      C1_OBS: '',
      C1_LOCAL: '',
      C1_CC: '',
      C1_DATPRF: '',
      CUSTOM_CLASSIFICATION: ''
    })
    setFormData({ ...formData, filial: item, items: []});
  };


  const handleAmz = async (value) => {
    setFormValue({ ...formValue, C1_LOCAL: value });
    if(value.length > 1){
      setLoading('amz');
      try {
        const response = amzList.filter((item) => item.NNR_CODIGO.includes(value));
        setLoading(null);
        setListAmz(response);
      } catch (error) {
        setLoading(null);
        console.error("Error fetching AMZ:", error);
      }
    }
  }

  const selectAmz = (item) => {
    // setListAmz([]);
    setFormValue({ ...formValue, C1_LOCAL: item });
  }



  // useEffect(() => {
  //   if(isPropagate) {
      
  //   }
  // }, [formData, isPropagate]);



  const handlePropague = () => {
    setIsPropagate(true);
    const acompanhantesEncontrados = formData.items.reduce((acc, item) => {
      if (item.acompanhantes && item.acompanhantes.length > 0) {
        return item.acompanhantes;
      }
      return acc;
    }, []);

    if (acompanhantesEncontrados.length > 0) {
      const values = formData.items.map((item) => ({
        ...item,
        acompanhantes: acompanhantesEncontrados
      }));
      setFormData({ ...formData, items: values });
    }
  }
  const handlePropagueOff = () => {
    setIsPropagate(false);
    setFormData({ ...formData, acompanhantes: [] });
  }


  const handlePrd = async (value) => {
    if(value.length > 2){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sc2010'
          }
        });
        setLoadingInput(false);
        setListPrd(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching AMZ:", error);
      }
    }
  }

  const selectPrd = (item) => {
    setFormValue({ ...formValue, C1_OP: item.c2_num });
  }





  const handleClass = async (value) => {
    if(value.length > 1){
      setLoading('cls');
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/classe_valor`, {
          params: { value }
        });
        setLoading(null);
        setListCls(response.data);
      } catch (error) {
        setLoading(null);
        console.error("Error fetching AMZ:", error);
      }
    }
  }

  const selectClass = (item) => {
    setFormValue({ ...formValue, C1_CLVL: item });
  }






  const handleAddItem = () => {
    setValueUsuario('');
    if (!formValue.C1_PRODUTO) {
      enqueueSnackbar("Produto é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_QUANT) {
      enqueueSnackbar("Quantidade é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_LOCAL) {
      enqueueSnackbar("Armazém é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_CC) {
      enqueueSnackbar("Centro de Custo é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_DATPRF) {
      enqueueSnackbar("Data de necessidade é obrigatório", { variant: "error" });
      return;
    }



    const nextC1_ITEM = formData.items.length + 1;

    const newItem = {
      ...formValue,
      C1_ITEM: nextC1_ITEM,
    };

    setFormData({
      ...formData,
      items: [...formData.items, newItem],
    });

    setFormValue({
      C1_ITEM: nextC1_ITEM + 1,
      C1_PRODUTO: '',
      C1_SOLICIT: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
      C1_UM: '',
      C1_DESCRI: '',
      C1_QUANT: 1,
      C1_CLVL: '',
      C1_OP: '',
      C1_OBS: '',
      C1_LOCAL: '',
      C1_CC: '',
      C1_DATPRF: '',
      CUSTOM_CLASSIFICATION: '',
      acompanhantes: []
    });
  }


  useEffect(() => {
    if (formValue.C1_DATPRF) {
      const dataLimite7Dias = moment().add(7, 'days');
      const dataLimite6Dias = moment().add(6, 'days');
      const dataNecessidade = moment(formValue.C1_DATPRF);
  
      if (dataNecessidade.isBefore(dataLimite7Dias)) {
        setFormValue({ ...formValue, CUSTOM_CLASSIFICATION: 'Emergencial' });
      } 
      
      if (dataNecessidade.isAfter(dataLimite6Dias)) {
        setFormValue({ ...formValue, CUSTOM_CLASSIFICATION: 'Programada' });
      }
    }
  }, [formValue.C1_DATPRF]);
  

  const openEdit = (item) => {
    setFormValue({
      C1_PRODUTO: item.C1_PRODUTO,
      C1_QUANT: item.C1_QUANT,
      C1_DESCRI: item.C1_DESCRI,
      C1_ITEM: item.C1_ITEM,
      C1_UM: item.C1_UM,
      C1_CLVL: item.C1_CLVL,
      C1_OP: item.C1_OP,
      C1_ITEMCTA: item.C1_ITEMCTA,
      C1_OBS: item.C1_OBS,
      C1_LOCAL: item.C1_LOCAL,
      C1_CC: item.C1_CC,
      C1_DATPRF: item.C1_DATPRF,
      CUSTOM_CLASSIFICATION: item.CUSTOM_CLASSIFICATION,
      acompanhantes: item.acompanhantes
    })
  }








  function removerCaracteresEspeciais(texto) {
    return texto
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ç/g, 'c') 
        .replace(/Ç/g, 'C')
        .replace(/[^\w\s]/g, '');
  }


  const handleConclusion = async () => {
    setLoadingRequest(true);
    const data = {
      ...formData,
      items: formData.items.map((item) => {
        return {
          ...item,
          C1_DATPRF: moment(item.C1_DATPRF).format('YYYYMMDD'),
          C1_SOLICIT: `${user.name} ${user.last_name}`,
          C1_EMISSAO: moment().format('YYYYMMDD'),
        }
      })
    };
    
    try {
      const response = await api.post(`/api/v2/modules/gestao-compras/solicitacao-compras/`, data);
      setLoadingRequest(false);
      handleGroupData(response.data, 'isGet');
      enqueueSnackbar("Solicitação de compra criada com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setFormData({
        dataemissao: moment().format('YYYYMMDD'),
        usuario: user.userId,
        solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
        filial: "",
        comprador_preferencial: null,
        items: []
      });
    } catch (error) {
      console.error("Error creating request:", error);
      setLoadingRequest(false);
    }
  }








  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {setOpen(false)}}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2 }}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>SOLICITAÇÃO DE COMPRAS</h3>
            </div>
          </Toolbar>
        </AppBar>

        <div className='form-container'>
          <div className='form-pushare' style={{height: '100%'}}>
            <div style={{height: '100vh', marginBottom: 100}}>

           
            

            <div className='form-row' style={{height: 80, padding: 15, border: '1px solid #e7e4e4', borderRadius: 5, display: 'flex', alignItems: 'center'}}>
              <div className='form-group' style={{width: '20%', position: 'relative', display: 'flex', flexDirection: 'column', marginRight: 10}}>
                <label style={{marginLeft: 4, fontSize: 12}}>Filial</label>
                <InputPicker
                  id="cc"
                  value={formData.filial}
                  block
                  onSelect={(value) => selectFilial(value)}
                  placeholder="Selecione A filial"
                  data={filialList.map((item) => 
                    ({
                        label: `${item.value} - ${item.label}`,
                        value: item?.value
                    }))}
                  menuStyle={{
                    maxWidth: '100%',
                    overflowX: 'auto',
                  }}
                  renderMenu={(menu) => {
                    if(loadingInput){
                      return(
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                          <Loader content="Carregando..." center/>
                        </div>
                      )
                    } return(
                      <div style={{fontSize: 11}}>
                        {menu}
                      </div>
                    )
                  }}
                />
              </div>

              <div className='form-group' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <label style={{marginLeft: 4, fontSize: 12}}>Comprador de Preferencia</label>
                <InputPicker
                  id="cc"
                  value={formData.comprador_preferencial}
                  block
                  onSelect={(value) => setFormData({ ...formData, comprador_preferencial: value })}
                  placeholder="Selecione o comprador"
                  data={listUser
                    .filter(item => item?.is_comprador)
                    .map(item => ({                     
                      label: `${item.first_name} ${item.last_name}`,
                      value: item.id
                    }))}
                  menuStyle={{
                    maxWidth: '100%',
                    overflowX: 'auto',
                  }}
                  onClean={() => {
                    setFormData({ ...formData, comprador_preferencial: null });
                  }}
                  renderMenu={(menu) => {
                    if(loadingInput){
                      return(
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                          <Loader content="Carregando..." center/>
                        </div>
                      )
                    } return(
                      <div style={{fontSize: 11}}>
                        {menu}
                      </div>
                    )
                  }}
                />
              </div>
            </div>


            <div style={{padding: 15, border: '1px solid #e7e4e4', borderRadius: 5}}>
              <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                <div className='form-group input-product-container' style={{width: '40%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Produto</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    value={formValue.C1_PRODUTO}
                    onSearch={(e) => handleProduto(e.toUpperCase())}
                    onSelect={(value) => selectProduct(value)}
                    data={productList.map((item) => 
                      ({
                          label: `${item.b1_cod} - ${item.b1_desc}`,
                          value: item?.b1_cod
                      }))}
                    placeholder="Código ou nome"
                    menuStyle={{
                      maxWidth: '10%',
                      overflowX: 'auto',
                    }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>
                

                <div className='form-group' style={{width: '100%', marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Descrição</label>
                  <Input
                    type="text"
                    id="cc"
                    value={formValue.C1_DESCRI}
                    placeholder=""
                  />
                </div>

                <div className='form-group' style={{width: '10%', marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                  <Input
                    type="text"
                    id="cc"
                    readOnly
                    value={formValue.C1_UM || 'UN'}
                    placeholder=""
                  />
                </div>

              </div>
              



              <div className='form-row' style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              

                <div className='form-group' style={{marginRight: 10, position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Armazém</label>
                  <InputPicker
                    type="text"
                    id="cc"
                    value={formValue.C1_LOCAL}
                    onSearch={(e) => handleAmz(e.toUpperCase())}
                    data={amzList.map((item) => 
                      ({
                          label: `${item.NNR_CODIGO} - ${item.NNR_DESCRI}`,
                          value: item?.NNR_CODIGO
                      }))}
                    placeholder="Amazém"
                    onSelect={(value) => selectAmz(value)}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                  
                </div>

                <div className='form-group' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Centro de Custo</label>
                  <InputPicker
                    id="cc"
                    value={formValue.C1_CC}
                    block
                    onSearch={(e) => handleCentroC(e.toUpperCase())}
                    onSelect={(value) => selectCentroC(value)}
                    placeholder="Digite o código ou nome do centro de custo"
                    data={centroCList.map((item) => 
                      ({
                          label: `${item.ctt_custo?.trim()} - ${item.ctt_desc01.trim()}`,
                          value: item?.ctt_custo?.trim()
                      }))}
                    menuStyle={{
                      maxWidth: '100%',
                      overflowX: 'auto',
                    }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>




              <div className='form-row-grid'>
                <div className='form-group grid' style={{marginRight: 10, position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Ordem de Produção</label>
                  <InputPicker
                    type="text"
                    className='custom-input-picker'
                    autoComplete='off'
                    value={formValue.C1_OP}
                    id="cc"
                    data={listPrd.map((item) => 
                      ({
                          label: item.c2_num?.trim() + ' - ' + item.c2_produto?.trim(),
                          value: item.c2_num?.trim()
                      }))}
                    onSearch={(e) => handlePrd(e.toUpperCase())}
                    onSelect={(value) => selectPrd(value)}
                    placeholder="N° da ordem de produção"
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>


                <div className='form-group grid' style={{marginRight: 10,  position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Classe de Valor</label>
                  <InputPicker
                    type="text"
                    id="cc"
                    className='custom-input-picker'
                    autoComplete='off'
                    onSearch={(e) => handleClass(e.toUpperCase())}
                    placeholder="Selecione"
                    data={listCls.map((item) => 
                      ({
                          label: `${item.cth_clvl?.trim()} - ${item.cth_desc01.trim()}`,
                          value: item.cth_clvl?.trim()
                      }))}
                    onSelect={(value) => selectClass(value)}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>


                <div className='form-group grid' style={{marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Necessidade</label>
                  <Input
                    type="date"
                    autoComplete='off'
                    style={{fontSize: 12, color: '#545454'}}
                    id="cc"
                    value={formValue.C1_DATPRF}
                    onChange={(e)=>setFormValue({ ...formValue, C1_DATPRF: e })}
                    placeholder=""
                  />
                </div>
                

                <div className='form-group grid' style={{marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Classificação</label>
                  <Input
                    type="text"
                    autoComplete='off'
                    readOnly
                    id="cc"
                    value={formValue.CUSTOM_CLASSIFICATION}
                    placeholder=""
                  />
                </div>

                <div className='form-group grid'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Quantidade</label>
                  <Input
                    type="number"
                    autoComplete='off'
                    id="cc"
                    value={formValue.C1_QUANT}
                    onChange={(e)=>setFormValue({ ...formValue, C1_QUANT: parseFloat(e) })}
                    placeholder=""
                  />
                </div>
                
              </div>

              <div className='form-row'>
                <div style={{width: '100%', zIndex: 999}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Marcar Usuarios:</label>
                    <TagPicker
                      style={{width: '100%'}}
                      block
                      data={listUser.map((item) => (
                        {
                          label: `${item.first_name} ${item.last_name}`,
                          value: item.id
                        }
                      ))}
                      value={formValue.acompanhantes}
                      placeholder="Adicionar Usuarios"
                      onChange={(value)=>setFormValue({...formValue, acompanhantes: value})}
                      renderMenu={(menu) => { 
                        return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                      onClean={() => {
                        setFormValue({ ...formValue, acompanhantes: [] });
                      }}
                    />
                </div> 
              </div>


                

              <div style={{width: '100%'}}>
                <label style={{marginLeft: 4, fontSize: 12}}>Observação</label>
                <Input as="textarea" rows={2} onChange={(value)=>setFormValue({ ...formValue, C1_OBS: removerCaracteresEspeciais(value.toUpperCase()) })} value={formValue.C1_OBS} placeholder="Observação" />
              </div>
            </div>
            

            </div>
            <div className='pushare-sc-bottom'>
              <IconButton title='Adicionar item' style={{backgroundColor: '#cecece', color: 'gray'}} icon={<ExpandOutlineIcon  style={{backgroundColor: '#cecece', color: 'gray'}}/>} onClick={()=>handleAddItem()} size='sm'>
                Adicionar
              </IconButton>
            </div>
          </div>
            
          <div className='list-pushare' style={{position: 'relative', overflowY: 'hidden', paddingLeft: 0, paddingRight: 0, paddingTop: 0}}>
            <div style={{overflowX: 'auto', height: '100vh', paddingLeft: 10, paddingTop: 10, paddingRight: 10}}>
              {loadingRequest ? (
                <Loader content="Salvando..."  center/> 
              ):(
                <>
                {formData.items.length > 0 ? (
                <>
                  <span>Itens adicionados:</span>
                  <ul className="list-container-puchare" style={{marginBottom: 120}}>
                      <div className='list-item-puchare-title' style={{top: '-10px'}}>
                        <span>Produto</span>
                        <span style={{textAlign: 'center'}}>Qtd.</span>
                        <span style={{textAlign: 'center'}}>Necessidade</span>
                        <span style={{textAlign: 'center'}}>Classificação</span>
                        <span style={{textAlign: 'center'}}>...</span>
                      </div>
                    {formData.items.map((item, index) => (
                      <>
                      <li key={index} className="list-item-puchare">
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                          <span>{item.C1_PRODUTO}</span>
                          <span title={item.C1_DESCRI} style={{fontSize: 9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 190, cursor: 'pointer'}}>{item.C1_DESCRI}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                          <span style={{textAlign: 'center'}}>{item.C1_QUANT}</span>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                          <span style={{textAlign: 'center'}}>{moment(item.C1_DATPRF, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{item.CUSTOM_CLASSIFICATION}</span>
                        </div>
                        

                        

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          <IconButton 
                          appearance='primary' 
                          style={{backgroundColor: '#bbbaba', marginRight: 10}} 
                          onClick={()=> {openEdit(item);
                          const updatedItems = formData.items.filter((_, i) => i !== index);
                          setFormData({...formData, items: updatedItems});
                          }} 
                          icon={<EditIcon style={{backgroundColor: '#bbbaba', color: '#fff'}}/>} 
                          size='sm'
                          />

                          <IconButton
                            appearance='primary'
                            style={{backgroundColor: 'red', marginRight: 10}}
                            onClick={() => {
                              const updatedItems = formData.items.filter((_, i) => i !== index);
                              setFormData({...formData, items: updatedItems});
                            }}
                            icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>}
                            size='sm'
                          />
                        </div>
                        
                      </li>
                      
                      </>
                      
                    ))}
                  </ul>
                </>
              ) : (
                <span>Nenhum item adicionado</span>
              )}
                </>
              )}
            </div>
            {formData.items.length > 0 && (
              <div style={{position: 'absolute', bottom: 0, height: '5%', zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1%'}}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1%'}}>
                    <div>
                    <Toggle checked={isPropagate} onChange={(value)=> value ? handlePropague() : handlePropagueOff()}/>
                    <span style={{marginLeft: 5, fontSize: 12}}>Marcar Todos Usuarios</span>                  
                    </div>
                  <div>
                    <IconButton appearance='primary' style={{backgroundColor: 'red', marginRight: 10}} onClick={()=> setFormData({...formData,items: []})} icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>} size='sm'>
                      Cancelar
                    </IconButton>
                    <IconButton appearance='primary' style={{backgroundColor: '#00664e'}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                      Concluir
                    </IconButton>
                  </div>
                  
                </div>
              </div>
            )}
          </div>
          <div className='btn-add-mobile'>
          <Button onClick={()=>handleAddItem()} block style={{backgroundColor: 'gray', color: '#fff'}}>
            Adicionar
          </Button>    
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DataFormDrawer;
