import './pd-cards.css';
import { useAxios } from '../../../../../../utils/api/api';
import { useEffect, useState, useContext } from 'react';
import { ListScContext } from '../../utils/scListContext';
import { CiCircleAlert } from "react-icons/ci";


const PdCards = () => {
    const {cardSelected, setCardSelected} = useContext(ListScContext);
    const api = useAxios();
    const [data, setData] = useState({
        concluido: 0,
        pendentes: 0,
        aprovacao: 0,
        reprovadas: 0,
        atendimento: 0,
        transporte: 0,
        cotacao: 0,
        aprovadas: 0,
        pedidos_sync: 0
    });



    const handleData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_data_cards');
            const {atendimento, aprovacao, concluido, solicitacoes, aprovadas, pedidos_sync, reprovadas} = response.data;
            
            // Recupera dados anteriores do localStorage
            const oldData = JSON.parse(localStorage.getItem('purchaseData')) || {};
            
            const newData = {
                concluido,
                aprovacao,
                atendimento,
                pendentes: solicitacoes,
                transporte: 0,
                cotacao: 0,
                aprovadas,
                pedidos_sync,
                reprovadas
            };

            // Só atualiza se os dados mudaram
            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                localStorage.setItem('purchaseData', JSON.stringify(newData));
                setData(newData);
            }

        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        const savedData = localStorage.getItem('purchaseData');
        if (savedData) {
            setData(JSON.parse(savedData));
        }
        handleData();
        
        const interval = setInterval(() => {
            handleData();
        }, 60000);

        // Limpa o intervalo quando o componente for desmontado
        return () => clearInterval(interval);
        }, []);

    return(
        <div className='container-data-cards pd'>
            <div className='data-card-item' style={{position: 'relative'}} title='Com base na data 01/01/2024 ate a atual' onClick={()=>setCardSelected('solicitacao')}>
                <span className='data-card-title pd'>SC. PENDENTES</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.pendentes}</span>
                </div>
                {cardSelected === 'solicitacao' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>
            

            


            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('aprovacao')}>
                <span className='data-card-title pd' style={{textAlign: 'center'}}>EM APROVAÇÃO</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aprovacao}</span>
                </div>
                {cardSelected === 'aprovacao' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('aprovada')}>
                <span className='data-card-title pd'>APROVADAS</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aprovadas}</span>
                </div>
                {cardSelected === 'aprovada' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('reprovadas')}>
                <span className='data-card-title pd'>REPROVADAS</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.reprovadas}</span>
                </div>
                {cardSelected === 'reprovadas' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('transporte')}>
                <span className='data-card-title pd'>EM TRANSPORTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.transporte}</span>
                </div>
                {cardSelected === 'transporte' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>
            

            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('concluida')}>
                <span className='data-card-title pd'>CONCLUIDAS</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.concluido}</span>
                </div>
                {cardSelected === 'concluida' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>

            

            <div className='data-card-item' style={{position: 'relative'}} onClick={()=>setCardSelected('sync')}>
                <span className='data-card-title pd' style={{textAlign: 'center'}}>ENVIO PENDENTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.pedidos_sync}</span>
                </div>
                
                {cardSelected === 'sync' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.4%', borderRadius: 0}}/>
                )}
            </div>

        </div>
    )
}

export default PdCards;